import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Logo from '../Images/logo/logo.png';
import { FaFacebookF, FaInstagram, FaYoutube } from 'react-icons/fa';
import Bg from '../Images/mockup/background.png';
import social1 from '../Images/icon-social/FB.png';
import social2 from '../Images/icon-social/IG.png';
import social3 from '../Images/icon-social/Youtube.png';
import social4 from '../Images/icon-social/tiktok.png';
import social5 from '../Images/icon-social/newsletter.png';

function MainFooter({ contents }) {
  return (
    <footer id="footer" className="pt-5 pb-0">
      <Container fluid className="position-relative container-footer p-0">
        <div className='position-absolute bottom-0 w-100 bg-color-primary bg-bar'></div>
        <div className='footer-content position-absolute bottom-0 w-100'>
          <Row className='m-0 mt-1 mt-lg-4 w-100 d-flex justify-content-between align-items-center'>
            <Col xs={6} className='px-2 px-xl-5'>
              <div className='d-lg-flex align-items-center'>
                <ul className="footer-social m-0 p-0">
                  <li className='d-inline-block me-1'>
                    <a href={contents?.contact?.facebook} target="_blank">
                      <img src={social1} className="social-icon" />
                    </a>
                  </li>
                  <li className='d-inline-block me-1'>
                    <a href={contents?.contact?.instagram} target="_blank">
                      <img src={social2} className="social-icon" />
                    </a>
                  </li>
                  <li className='d-inline-block me-1'>
                    <a href={contents?.contact?.youtube} target="_blank">
                      <img src={social3} className="social-icon" />
                    </a>
                  </li>
                  <li className='d-inline-block me-1'>
                    <a href={contents?.contact?.tiktok} target="_blank">
                      <img src={social4} className="social-icon" />
                    </a>
                  </li>
                  <li className='d-inline-block me-1'>
                    <a href={'mailto:' + contents?.contact?.email}>
                      <img src={social5} className="social-icon" />
                    </a>
                  </li>
                </ul>
                <ul className="footer-social m-0 p-0 d-none d-lg-block">
                  <li className='d-inline-block ms-3 me-2'>
                    <Link to="/Content/PrivacyPolicy" className='link-color-body'>Privacy Policy</Link>
                  </li>
                  <li className='d-inline-block me-1'>
                    <Link to="/Content/RefundAndCancellation" className='link-color-body'>Refund And Cancellation</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={6} className='text-end px-2 px-xl-5'>
              <img src={Logo} alt="Logo" className="logo" />
            </Col>
            <Col xs={12} className='d-block d-lg-none px-0 text-center'>
              <ul className="footer-social m-0 p-0">
                <li className='d-inline-block me-2'>
                  <Link to="/Content/PrivacyPolicy" className='link-color-body'>Privacy Policy</Link>
                </li>
                <li className='d-inline-block'>
                  <Link to="/Content/RefundAndCancellation" className='link-color-body'>Refund And Cancellation</Link>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </Container>
    </footer>
  )
}

export default MainFooter