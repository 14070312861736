import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function SectionEvent({ contents, isHome }) {
  return (
    <section className="pt-5 pb-5">
      <Container className='pt-5 pb-5'>
        {isHome ? (
          <>
            <Row className='mb-5'>
              <Col className='text-center fs-1'>
                <Link to='/Event' className="text-white text-decoration-none">
                  Events
                </Link>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Row>
                  {contents.map((item, index) => (
                    index === 0 ?
                      <Col xs={12} className="mb-4" key={index}>
                        <div className='position-relative ratio-1x1' style={{ backgroundImage: item.row_value }}>
                          <Link to='/Event/Event-01'>
                            <div className='position-absolute bottom-0 text-white bg-dark w-100 p-3 px-4 fs-5'>
                              <span className='position-absolute start-0 ps-4 fs-6' style={{ marginTop: '-50px' }}>{item.event_date}</span>
                              <span className='position-absolute end-0 pe-4 fs-6' style={{ marginTop: '-50px' }}>{item.trainer}</span>
                              {item.name}
                            </div>
                          </Link>
                        </div>
                      </Col>
                      : ''
                  ))}
                </Row>
              </Col>
              <Col xs={12} md={6}>
                <Row>
                  {contents.map((item, index) => (
                    index > 0 && index < 4 ?
                      <Col xs={12} md={6} className="mb-4" key={index}>
                        <div className='position-relative ratio-1x1' style={{ backgroundImage: item.row_value }}>
                          <Link to='/Event/Event-01'>
                            <div className='position-absolute bottom-0 text-white bg-dark w-100 p-3 px-4 fs-5'>
                              <span className='position-absolute start-0 ps-4 fs-6' style={{ marginTop: '-50px' }}>{item.event_date}</span>
                              <span className='position-absolute end-0 pe-4 fs-6' style={{ marginTop: '-50px' }}>{item.trainer}</span>
                              {item.name}
                            </div>
                          </Link>
                        </div>
                      </Col>
                      : ''
                  ))}
                  <Col xs={12} md={6} className="mb-4">
                    <div className='position-relative ratio-1x1'>
                      <div className='position-absolute top-50 start-50 translate-middle p-3 px-4 fs-5'>
                        <Link to='/Event'>
                          <Button variant='outline-light' className="rounded-pill fs-4 px-5">
                            More
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col xs={12} md={6}>
                <Row>
                  {contents.map((item, index) => (
                    index === 0 ?
                      <Col xs={12} className="mb-4" key={index}>
                        <div className='position-relative ratio-1x1' style={{ backgroundImage: item.row_value }}>
                          <Link to='/Event/Event-01'>
                            <div className='position-absolute bottom-0 text-white bg-dark w-100 p-3 px-4 fs-5'>
                              <span className='position-absolute start-0 ps-4 fs-6' style={{ marginTop: '-50px' }}>{item.event_date}</span>
                              <span className='position-absolute end-0 pe-4 fs-6' style={{ marginTop: '-50px' }}>{item.trainer}</span>
                              {item.name}
                            </div>
                          </Link>
                        </div>
                      </Col>
                      : ''
                  ))}
                </Row>
              </Col>
              <Col xs={12} md={6}>
                <Row>
                  {contents.map((item, index) => (
                    index > 0 && index < 5 ?
                      <Col xs={12} md={6} className="mb-4" key={index}>
                        <div className='position-relative ratio-1x1' style={{ backgroundImage: item.row_value }}>
                          <Link to='/Event/Event-01'>
                            <div className='position-absolute bottom-0 text-white bg-dark w-100 p-3 px-4 fs-5'>
                              <span className='position-absolute start-0 ps-4 fs-6' style={{ marginTop: '-50px' }}>{item.event_date}</span>
                              <span className='position-absolute end-0 pe-4 fs-6' style={{ marginTop: '-50px' }}>{item.trainer}</span>
                              {item.name}
                            </div>
                          </Link>
                        </div>
                      </Col>
                      : ''
                  ))}
                </Row>
              </Col>
            </Row>
            <Row>
              {contents.map((item, index) => (
                index >= 5 ?
                  <Col xs={12} md={3} className="mb-4">
                    <div className='position-relative ratio-1x1' style={{ backgroundImage: item.row_value }}>
                      <Link to='/Event/Event-01'>
                        <div className='position-absolute bottom-0 text-white bg-dark w-100 p-3 px-4 fs-5'>
                          <span className='position-absolute start-0 ps-4 fs-6' style={{ marginTop: '-50px' }}>{item.event_date}</span>
                          <span className='position-absolute end-0 pe-4 fs-6' style={{ marginTop: '-50px' }}>{item.trainer}</span>
                          {item.name}
                        </div>
                      </Link>
                    </div>
                  </Col>
                  : ''
              ))}
            </Row>
          </>
        )}
      </Container>
    </section>
  )
}

SectionEvent.defaultProps = {
  isHome: false,
  contents: [
    { id: 1, name: 'DaVinci Resolve for iPad Live', trainer: '', event_date: '2023-01-17', row_value: 'radial-gradient(circle at 0% 0%, rgb(249, 89, 60), #00423E 80%)' },
    { id: 1, name: 'คอร์สตัดต่อสำหรับมือใหม่ สายคอนเทนต์ครีเอเตอร์', trainer: 'โดย อาจารย์กุ้ง', event_date: '2023-01-28', row_value: 'radial-gradient(circle at 0% 0%, #1B2252, rgb(249, 89, 60) 80%)' },
    { id: 1, name: 'ทำงานพร้อมกันด้วย Blackmagic Cloud', trainer: '', event_date: '2023-02-15', row_value: 'radial-gradient(circle at 0% 0%, #1B2252, #00423E 80%)' },
    { id: 1, name: 'WOZ Meet up : DaVinci Resolve iPad users meet up @เชียงใหม่', trainer: '', event_date: '2023-03-18', row_value: 'radial-gradient(circle at 0% 0%, rgb(249, 89, 60), #00423E 80%)' },
    { id: 1, name: 'WOZ Meet up : DaVinci Resolve iPad users meet up @กรุงเทพ', trainer: '', event_date: '2023-04-01', row_value: 'radial-gradient(circle at 0% 0%, #1B2252, #00423E 80%)' },
    { id: 1, name: 'Blackmagic Design Webinar : What\'s new in DaVinci Resolve 18.5', trainer: '', event_date: '2023-05-02', row_value: 'radial-gradient(circle at 0% 0%, #1B2252, rgb(249, 89, 60) 80%)' },
  ]
}

export default SectionEvent