import React from 'react';
import SectionSpace from '../Components/SectionSpace';
import SectionBooking from '../Components/SectionBooking';

const Booking = ({contents}) => {
  return (
    <>
      <SectionSpace height={'90px'} />
      <SectionBooking location={contents?.locations} />
    </>
  )
}

export default Booking