// PRD = api/2.0
// PRD in subfolder = ../api/2.0
// DEV = http://envphp:8080/api/2.0
// PRD = api/2.0
// CALL PRD = http://www.domain.com/api/2.0
// CALL PRD HTTPS = https://www.domain.com/api/2.0
const useApi = true; // ** Don't forget to set
const urlApi = "api/2.0";
// const baseName = "/projects/campcayla-final";
const baseName = "";
const apiEndpointMapping = {
  '/': { name: "home", navColor: 'transparent', module: "home", call: 'get-content', useParam: false }, 
  '/Accommodation': { name: "", navColor: 'primary', module: "accom", call: 'get-content', useParam: true }, 
  '/Location': { name: "", navColor: 'primary', module: "location", call: 'get-content', useParam: true }, 
  '/About': { name: "", navColor: 'primary', module: "about", call: 'get-content', useParam: true }, 
  '/Contact': { name: "", navColor: 'primary', module: "contact", call: 'get-content', useParam: false }, 
  '/Booking': { name: "", navColor: 'primary', module: "booking", call: 'get-content', useParam: false }, 
  '/Content': { name: "", navColor: 'primary', module: "content", call: 'get-content', useParam: true }, 

  
  '/Glamping': { name: "", navColor: 'primary', module: "home", call: 'get-content', useParam: false }, 
  '/OnGridCamping': { name: "", navColor: 'primary', module: "home", call: 'get-content', useParam: false }, 
  '/OffGridCamping': { name: "", navColor: 'primary', module: "home", call: 'get-content', useParam: false }, 
  '/CarCamping': { name: "", navColor: 'primary', module: "home", call: 'get-content', useParam: false }, 

  '/Eden': { name: "", navColor: 'primary', module: "home", call: 'get-content', useParam: false }, 
  '/Stellar': { name: "", navColor: 'secondary', module: "home", call: 'get-content', useParam: false }, 
  '/Coasta': { name: "", navColor: 'primary', module: "home", call: 'get-content', useParam: false }, 
  
  '/PrivacyPolicy': { name: "", navColor: 'primary', module: "content", call: 'get-content', useParam: false }, 
  '/RefundAndCancellation': { name: "", navColor: 'primary', module: "content", call: 'get-content', useParam: false }, 
};

export { useApi, urlApi, baseName, apiEndpointMapping }