import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { urlApi } from '../Constants/Global';
import Logo from '../Images/update2/contact/LOGO-CAMP-CAYLA.png';
import Sign1 from '../Images/update2/contact/eden.png';
import Sign2 from '../Images/update2/contact/stellar.png';
import Sign3 from '../Images/update2/contact/rayong.png';
import IconPhone from '../Images/update2/contact/tel.png';
import IconEmail from '../Images/update2/contact/e-mail.png';
import IconLine from '../Images/update2/contact/line.png';

const SectionContact = ({ contact, branch }) => {

  const storage = urlApi + '/storage/media/';

  return (
    <section className='booking'>
      <Container fluid className='px-5 px-xl-0'>
        <Row>
          <Col sm={{ span: 6, offset: 3 }} md={{ span: 6, offset: 4 }}>
            <div className='fs-custom-3 fw-bold mb-3 text-uppercase tx-color-dark tx-spacing-10'>Contact</div>
          </Col>
        </Row>
        <Row>
          <Col sm={{ span: 6, offset: 3 }} md={{ span: 6, offset: 4 }}>
            <div className='d-flex'>
              <div>
                <img src={Logo} style={{ width: '100px' }} />
              </div>
              <div className='px-3'>
                <h6 className='text-uppercase fw-bold tx-color-primary mb-0'>{contact.topic}</h6>
                <ul className='p-0 m-0 text-nowrap' style={{ listStyleType: 'none' }}>
                  <li><img src={IconPhone} style={{ width: '20px' }} /> : <a href={'tel:' + contact.phone} className='text-decoration-none tx-color-dark'>{contact.phone}</a></li>
                  <li><img src={IconLine} style={{ width: '20px' }} /> : <a href={contact.linelink} className='text-decoration-none tx-color-dark' target='_blank'>{contact.line}</a></li>
                  <li><img src={IconEmail} style={{ width: '20px' }} /> : <a href={'mailto:' + contact.email} className='text-decoration-none tx-color-dark'>{contact.email}</a></li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={{ span: 6, offset: 3 }} md={{ span: 5, offset: 4 }}>
            <hr className='mt-4 mb-4' style={{ borderTop: '1px solid black', opacity: '1' }} />
          </Col>
        </Row>

        {branch.map((item, index) => (
          <Row className='mb-4' key={index}>
            <Col sm={{ span: 6, offset: 3 }} md={{ span: 6, offset: 4 }}>
              <div className='d-flex'>
                <div>
                  <img src={storage + item.header_icon} style={{ width: '70px' }} />
                </div>
                <div className='px-3'>
                  <h6 className='text-uppercase fw-bold tx-color-primary mb-0'>{item.contact_topic}</h6>
                  <ul className='p-0 m-0 text-nowrap' style={{ listStyleType: 'none' }}>
                    <li><img src={IconPhone} style={{ width: '20px' }} /> : <a href={'tel:' + item.contact_phone} className='text-decoration-none tx-color-dark'>{item.contact_phone}</a></li>
                    <li><img src={IconEmail} style={{ width: '20px' }} /> : <a href={'mailto:' + item.contact_email} className='text-decoration-none tx-color-dark'>{item.contact_email}</a></li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        ))}

      </Container>
    </section>
  )
}

SectionContact.defaultProps = {
  contact: {},
  branch: []
}

export default SectionContact