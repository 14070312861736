import React from 'react';
import { urlApi } from '../Constants/Global';
import SectionAbout from '../Components/SectionAbout';
import SectionCarousel from '../Components/SectionCarousel';
import Photo1 from '../Images/final/9-about/head.png';

const About = ({ contents }) => {
  const storage = urlApi + '/storage/media/';

  const slide = [
    { row_value: storage + contents?.content?.header_image, detail: '' }
  ]

  return (
    <>
      <SectionCarousel showNav={false} showIndicator={false} showCaretDown={false} contents={slide} />
      <SectionAbout contents={contents.content} />
    </>
  )
}

About.defaultProps = {
  contents: {}
}

export default About