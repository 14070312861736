import React from 'react';
import { urlApi } from '../Constants/Global';
import { Link } from 'react-router-dom';
import SectionCarousel from '../Components/SectionCarousel';
import { Container, Row, Col } from 'react-bootstrap';
import Pic1 from '../Images/mockup/location/photo-stellar-1.png';

import Photo1 from '../Images/update2/stellar/HEAD-STELLAR.png';
import Map from '../Images/final-final/location-stellar/map.png';
import MapArea from '../Images/final-final/location-stellar/map-area.jpg';

import Album1 from '../Images/final-final/location-stellar/slide/01.png';
import Album2 from '../Images/final-final/location-stellar/slide/02.png';
import Album3 from '../Images/final-final/location-stellar/slide/03.png';
import Album4 from '../Images/final-final/location-stellar/slide/04.png';
import Album5 from '../Images/final-final/location-stellar/slide/05.png';
import Album6 from '../Images/final-final/location-stellar/slide/06.png';
import Album7 from '../Images/final-final/location-stellar/slide/07.png';
import Album8 from '../Images/final-final/location-stellar/slide/08.png';
import Album9 from '../Images/final-final/location-stellar/slide/09.png';
import Album10 from '../Images/final-final/location-stellar/slide/10.png';
import Album11 from '../Images/final-final/location-stellar/slide/11.png';
import Album12 from '../Images/final-final/location-stellar/slide/12.png';
import Album13 from '../Images/final-final/location-stellar/slide/13.png';

import Offer1 from '../Images/final-final/location-stellar/activity/01.png';
import Offer2 from '../Images/final-final/location-stellar/activity/02.png';
import Offer3 from '../Images/final-final/location-stellar/activity/03.png';
import Offer4 from '../Images/final-final/location-stellar/activity/04.png';
import Offer5 from '../Images/final-final/location-stellar/activity/05.png';
import Offer6 from '../Images/final-final/location-stellar/activity/06.png';
import Offer7 from '../Images/final-final/location-stellar/activity/07.png';

import Sign1 from '../Images/final/logo/stellar.png';
import SectionAccomodation from '../Components/SectionAccomodation';
import SectionLocationExplore from '../Components/SectionLocationExplore';

const Location = ({ contents }) => {
  console.log(contents)
  const storage = '../' + urlApi + '/storage/media/';

  const mockup = {
    slide: [
      { row_value: Photo1, detail: <></> }
    ],
    slideSecondary: [
      { row_value: Album1, detail: <></> },
      { row_value: Album2, detail: <></> },
      { row_value: Album3, detail: <></> },
      { row_value: Album4, detail: <></> },
      { row_value: Album5, detail: <></> },
      { row_value: Album6, detail: <></> },
      { row_value: Album7, detail: <></> },
      { row_value: Album8, detail: <></> },
      { row_value: Album9, detail: <></> },
      { row_value: Album10, detail: <></> },
      { row_value: Album11, detail: <></> },
      { row_value: Album12, detail: <></> },
      { row_value: Album13, detail: <></> },
    ],
    slideOffer: [
      { row_value: Offer1, detail: <></> },
      { row_value: Offer2, detail: <></> },
      { row_value: Offer3, detail: <></> },
      { row_value: Offer4, detail: <></> },
      { row_value: Offer5, detail: <></> },
      { row_value: Offer6, detail: <></> },
      { row_value: Offer7, detail: <></> },
    ]
  }

  const header = [
    { row_value: storage + contents?.content?.header_img, detail: '' }
  ];

  const slide = [];
  contents?.content?.content_slide?.map((item) =>
    slide.push({ row_value: storage + item, detail: '' })
  );

  const activity_slide = [];
  contents?.content?.activity_slide?.map((item) =>
    activity_slide.push({ row_value: storage + item, detail: '' })
  );

  return (
    <>
      <SectionCarousel showNav={false} showIndicator={false} showCaretDown={false} contents={header} />
      <section className='custom-header position-relative location-header'>
        <Container fluid className='content-top'>
          <Row>
            <Col className='text-center'>
              <img src={storage + contents?.content?.header_icon} className='location-sign' alt="sign" />
            </Col>
          </Row>
          <Row className='mb-4 mb-xl-5'>
            <Col className='text-center px-5'>
              <div className="fs-custom-1 fw-bold pt-5 mb-5 text-uppercase tx-color-dark tx-spacing-10 lh-1">
                {contents?.content?.name} - {contents?.content?.location_name}
              </div>
              <p
                className='fs-custom-5 lg-12 mb-3 mb-xl-4'
                dangerouslySetInnerHTML={{
                  __html: contents?.content?.detail
                    ?.replace(/\n\n/g, '<DOUBLE_NEWLINE>') // Replace double newlines with a placeholder
                    .replace(/\n/g, '<br class="mobile-br"/>') // Replace single newlines with <br>
                    .replace(/<DOUBLE_NEWLINE>/g, '<br/><br/>') // Restore double newlines
                }}>
              </p>

            </Col>
          </Row>
        </Container>
        <Container fluid className='px-5 px-xl-0'>
          <Row className='mb-1 mb-xl-2'>
            <Col xl={{ span: 8, offset: 2 }}>
              <SectionCarousel showNav={false} showIndicator={true} showCaretDown={false} contents={slide} />
            </Col>
          </Row>
          {contents?.content?.area_img !== null && contents?.content?.area_img !== '' ?
            <Row className='mb-1 mb-xl-2'>
              <Col xl={{ span: 8, offset: 2 }}>
                <img src={storage + contents?.content?.area_img} className='w-100' alt="Pic" />
              </Col>
            </Row>
            : ''}
          <Row className='mb-1 mb-xl-2'>
            <Col xl={{ span: 8, offset: 2 }}>
              <a href={contents?.content?.map_link} target='_blank'>
                <img src={storage + contents?.content?.map_img} className='w-100' alt="Pic" />
              </a>
            </Col>
          </Row>
          <Row className='mb-5'>
            <Col xl={{ span: 8, offset: 2 }}>
              <section className='mb-home-accom'>
                <Container fluid className='text-center'>
                  <Row className='accom-list text-center'>
                    <Col>
                      <Row>
                        {contents?.accoms?.map((item, index) => (
                          <Col key={index} className='p-0'>
                            {item.accom_available === '0' ?
                              <div className='position-relative accom-item' style={{ filter: 'grayscale(1)' }}>
                                <img src={storage + item.icon_bg} alt='background' className='w-100 accom-bg' />
                                <div className='position-absolute top-0 start-0'>
                                  <img src={storage + item.icon_img} alt='icon' className='accom-icon' style={{ width: '85%', marginTop: '7.5%' }} />
                                </div>
                              </div>
                              :
                              <Link to={'/Accommodation/' + item.row_slug}>
                                <div className='position-relative accom-item'>
                                  <img src={storage + item.icon_bg} alt='background' className='w-100 accom-bg' />
                                  <div className='position-absolute top-0 start-0'>
                                    <img src={storage + item.icon_img} alt='icon' className='accom-icon' style={{ width: '85%', marginTop: '7.5%' }} />
                                  </div>
                                </div>
                              </Link>
                            }
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </section>
            </Col>
          </Row>
        </Container>

        <Container fluid className='bg-color-primary pt-xl-5 pb-xl-5'>
          <Container>
            <Row>
              <Col xl={7} className='px-4 pe-xl-5 order-2 order-xl-1'>
                <div className='px-3 px-xl-0 pt-xl-5 pb-xl-5 location-offer-slide'>
                  <SectionCarousel
                    showNav={false}
                    showIndicator={true}
                    showCaretDown={false}
                    contents={activity_slide}
                    bgClass={'bg-transparent'}
                    mainClass={'left-top-round'}
                    moreClass={'custom-ratio-slide'}
                    indicatorClass={'location-indicator'}
                  />
                </div>
              </Col>
              <Col xl={5} className='px-5 px-xl-0 order-1 order-xl-2'>
                <div className="fs-custom-3 fw-bold mb-4 mt-5 mt-xl-5 text-uppercase tx-color-secondary tx-spacing-10 lh-1" dangerouslySetInnerHTML={{ __html: contents?.content?.activity_topic?.replace(/\n/g, "<br>") }}></div>
                <p className='fs-custom-5 text-white lg-12' style={{ marginBottom: '30px' }} dangerouslySetInnerHTML={{ __html: contents?.content?.activity_detail?.replace(/\n/g, "<br>") }}></p>
              </Col>
            </Row>
          </Container>
        </Container>

        {contents?.content?.row_embed !== null && contents?.content?.row_embed !== '' ?
          <Container fluid>
            <Row>
              <Col lg={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }} className="px-5 px-xl-0 pb-0 pt-5 mb-0 mt-5 mb-xl-0">
                <div className='embed-video-container mt-5' dangerouslySetInnerHTML={{ __html: contents?.content?.row_embed }}></div>
              </Col>
            </Row>
          </Container>
          : ''}

      </section>

      <SectionLocationExplore current={'/' + contents?.content?.row_slug} contents={contents?.locations} />
    </>
  )
}

Location.defaultProps = {
  contents: {
    lang: 'th',
    content: {
      contents: []
    }
  },
  path: '',
  lang: 'th',
  slides: [],
  slide_events: []
}

export default Location