import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { urlApi } from '../Constants/Global';
import Logo from '../Images/logo/logo-gn.png';
import Photo1 from '../Images/final/9-about/ourstory.png';
import Photo2 from '../Images/final/9-about/philosophy.png';
import Photo3 from '../Images/final/9-about/sustainability.png';
import Photo4 from '../Images/final/9-about/community.png';


const SectionAbout = ({ contents }) => {

  const storage = urlApi + '/storage/media/';

  const setDynamicHeight = () => {
    const windowWidth = window.innerWidth;
    const container = document.querySelectorAll('.auto-relative-height');

    container.forEach(element => {
      const children = element ? element.children : [];

      let maxHeight = 0;

      const updateMaxHeight = () => {
        for (let i = 0; i < children.length; i++) {
          const childHeight = children[i].offsetTop + children[i].offsetHeight;
          if (childHeight > maxHeight) {
            maxHeight = childHeight;
          }
        }

        console.log(maxHeight);

        if (windowWidth <= 1199) {
          // in case of mobile size just disable it.
          element.style.height = 'unset';
        } else {

        }
        element.style.height = maxHeight + 'px';
      };

      updateMaxHeight();

    });
  };

  useEffect(() => {
    setDynamicHeight();

    // Event listener for window resize
    const handleResize = () => {
      setDynamicHeight();
      // You may also want to call setDynamicHeader() here if needed
    };

    // Attach event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [contents]);

  return (
    <section className='about'>
      <Container fluid className='px-0'>
        <Row className='mt-5 mb-0 pt-5 pb-5 w-100'>
          <Col xl={12} className='text-center'>
            <img src={Logo} className='logo' alt="logo" />
            <h1 className='mt-3 mb-0 fw-bold text-uppercase tx-color-secondary'>{contents?.header_topic}</h1>
          </Col>
        </Row>

        <Row className='pt-5 pb-5 mx-0 w-100'>
          <Col xl={{ span: 6, offset: 1 }} className='px-5 px-xl-0' style={{ overflow: 'hidden', borderRadius: '175px 0px 0px 0px' }}>
            <img src={storage + contents?.content1_image} alt={contents?.content1_topic} className='w-100 pb-4 pb-xl-5' />
          </Col>
          <Col xl={5} className='p-0 px-0 px-xl-0'>
            <div className='auto-relative-height position-relative'>
              <div className='position-absolute bg-color-primary bg-about right'>
                <div className='fs-custom-6 fw-bold tx-color-secondary text-uppercase tx-spacing-10 mb-3 mb-xl-3 lh-12'>{contents?.content1_topic}</div>
                <p className='fs-custom-5 text-white' dangerouslySetInnerHTML={{ __html: contents?.content1_detail }}></p>
              </div>
            </div>
          </Col>
        </Row>

        <Row className='pt-5 pb-5 mx-0 w-100'>
          <Col xl={{ span: 6, offset: 0 }} className='px-5 px-xl-0 order-xl-2' style={{ overflow: 'hidden', borderRadius: '0px 175px 0px 0px' }}>
            <img src={storage + contents?.content2_image} alt={contents?.content2_topic} className='w-100 pb-4 pb-xl-5' />
          </Col>
          <Col xl={5} className='order-xl-1 p-0 px-0 px-xl-0'>
            <div className='auto-relative-height position-relative'>
              <div className='position-absolute bg-color-primary bg-about left'>
                <div className='fs-custom-6 fw-bold tx-color-secondary text-uppercase tx-spacing-10 mb-3 mb-xl-3 lh-12'>{contents?.content2_topic}</div>
                <p className='fs-custom-5 text-white' dangerouslySetInnerHTML={{ __html: contents?.content2_detail }}></p>
              </div>
            </div>
          </Col>
        </Row>

        <Row className='pt-5 pb-5 mx-0 w-100'>
          <Col xl={{ span: 6, offset: 1 }} className='px-5 px-xl-0' style={{ overflow: 'hidden', borderRadius: '175px 0px 0px 0px' }}>
            <img src={storage + contents?.content3_image} alt={contents?.content3_topic} className='w-100 pb-4 pb-xl-5' />
          </Col>
          <Col xl={5} className='p-0 px-0 px-xl-0'>
            <div className='auto-relative-height position-relative'>
              <div className='position-absolute bg-color-primary bg-about right'>
                <div className='fs-custom-6 fw-bold tx-color-secondary text-uppercase tx-spacing-10 mb-3 mb-xl-3 lh-12'>{contents?.content3_topic}</div>
                <p className='fs-custom-5 text-white' dangerouslySetInnerHTML={{ __html: contents?.content3_detail }}></p>
              </div>
            </div>
          </Col>
        </Row>

        <Row className='pt-5 pb-5 mx-0 w-100'>
          <Col xl={{ span: 6, offset: 0 }} className='px-5 px-xl-0 order-xl-2' style={{ overflow: 'hidden', borderRadius: '0px 175px 0px 0px' }}>
            <img src={storage + contents?.content4_image} alt={contents?.content4_topic} className='w-100 pb-4 pb-xl-5' />
          </Col>
          <Col xl={5} className='order-xl-1 p-0 px-0 px-xl-0'>
            <div className='auto-relative-height position-relative'>
              <div className='position-absolute bg-color-primary bg-about left'>
                <div className='fs-custom-6 fw-bold tx-color-secondary text-uppercase tx-spacing-10 mb-3 mb-xl-3 lh-12'>{contents?.content4_topic}</div>
                <p className='fs-custom-5 text-white' dangerouslySetInnerHTML={{ __html: contents?.content4_detail }}></p>
              </div>
            </div>
          </Col>
        </Row>

        {/* <Row className='mb-5 pt-xl-5 pb-xl-5'>
          <Col xl={7} className='order-1 order-xl-2'>
            <img src={Photo2} alt="ourstory" className='w-100 pb-4 pb-xl-5' />
          </Col>
          <Col xl={5} className='order-2 order-xl-1 p-0 px-2 px-xl-5 ps-xl-3'>
            <div className='fs-custom-6 fw-bold tx-color-primary text-uppercase tx-spacing-10 mb-0 mb-xl-3'>Philosophy</div>
            <p className='fs-custom-5'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus lobortis at ipsum ac molestie. Duis ac molestie nulla, commodo interdum sapien. Nam tempus, nunc eget bibendum commodo, orci sapien laoreet justo, sit amet tincidunt arcu leo sit amet dui. Nam dignissim convallis eros, non feugiat ligula maximus molestie. Cras volutpat finibus dignissim. Sed non leo a arcu condimentum laoreet eu dignissim nisi. Donec aliquam sapien orci, at facilisis arcu molestie eu.</p>
          </Col>
        </Row>
        <Row className='mb-5 pt-xl-5 pb-xl-5'>
          <Col xl={7}>
            <img src={Photo3} alt="ourstory" className='w-100 pb-4 pb-xl-5' />
          </Col>
          <Col xl={5} className='p-0 px-2 px-xl-5'>
            <div className='fs-custom-6 fw-bold tx-color-primary text-uppercase tx-spacing-10 mb-0 mb-xl-3'>Sustainability</div>
            <p className='fs-custom-5'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus lobortis at ipsum ac molestie. Duis ac molestie nulla, commodo interdum sapien. Nam tempus, nunc eget bibendum commodo, orci sapien laoreet justo, sit amet tincidunt arcu leo sit amet dui. Nam dignissim convallis eros, non feugiat ligula maximus molestie. Cras volutpat finibus dignissim. Sed non leo a arcu condimentum laoreet eu dignissim nisi. Donec aliquam sapien orci, at facilisis arcu molestie eu.</p>
          </Col>
        </Row>
        <Row className='mb-5 pt-xl-5'>
          <Col xl={7} className='order-1 order-xl-2'>
            <img src={Photo4} alt="ourstory" className='w-100 pb-4 pb-xl-5' />
          </Col>
          <Col xl={5} className='order-2 order-xl-1 p-0 px-2 px-xl-5 ps-xl-3'>
            <div className='fs-custom-6 fw-bold tx-color-primary text-uppercase tx-spacing-10 mb-0 mb-xl-3'>Community</div>
            <p className='fs-custom-5'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus lobortis at ipsum ac molestie. Duis ac molestie nulla, commodo interdum sapien. Nam tempus, nunc eget bibendum commodo, orci sapien laoreet justo, sit amet tincidunt arcu leo sit amet dui. Nam dignissim convallis eros, non feugiat ligula maximus molestie. Cras volutpat finibus dignissim. Sed non leo a arcu condimentum laoreet eu dignissim nisi. Donec aliquam sapien orci, at facilisis arcu molestie eu.</p>
          </Col>
        </Row> */}
      </Container>
    </section>
  )
}

export default SectionAbout