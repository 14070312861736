import React, { useState } from 'react';
import { urlApi } from '../../Constants/Global';
import { Carousel, Col } from 'react-bootstrap';
import DOMPurify from 'dompurify';

const SlidePrimary = ({ contents }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <Col>
        <Carousel activeIndex={index} onSelect={handleSelect}>
          {contents.map((item, index) => (
            <Carousel.Item key={index} className="ratio-21x9 bg-img" style={{backgroundImage: `url(${urlApi}/storage/media/${item.set2})`}}>
              <div className="position-absolute w-100 h-100">
                <Carousel.Caption className="top-50 start-50 translate-middle w-100 text-center">
                  {item.set1 !== '' ?
                  <div class="HTML-Displayer ql-editor" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.set1) }} />
                  : ''}
                </Carousel.Caption>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Col>
    </>
  )
}

SlidePrimary.defaultProps = {
  contents: []
}

export default SlidePrimary