import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { urlApi } from '../../Constants/Global';

const Class = ({ contents }) => {
  return (
    <Container fluid>
      <Row>
        {contents.map((item, index) => (
          <Col key={index} xs={12} className="p-0">
            <div className="position-relative ratio-21x9 text-center bg-img bg-color-primary" style={{ backgroundImage: `url(${urlApi}/storage/media/${item.set1})` }}>
              {item.set3 !== '' ?
                <Link to={item.set3}>
                  <Button variant='outline-light' className="position-absolute rounded-pill bottom-0 translate-middle mb-5 fs-4 px-5">
                    {item.set2}
                  </Button>
                </Link>
                :
                <Button variant='outline-light' className="position-absolute rounded-pill bottom-0 translate-middle mb-5 fs-4 px-5">{item.set2}</Button>
              }
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

Class.defaultProps = {
  contents: []
}

export default Class