import React from 'react';
import { urlApi } from '../Constants/Global';
import SectionCarousel from '../Components/SectionCarousel';
import { Container, Row, Col } from 'react-bootstrap';

import Pic1 from '../Images/mockup/location/photo-eden-1.png';
import Pic2 from '../Images/mockup/location/eden-1.png';
import Pic3 from '../Images/mockup/location/eden-2.png';

import Photo1 from '../Images/update2/eden/HEAD-EDEN.png';
import Map from '../Images/final-final/location-eden/map.png';

import Album1 from '../Images/final-final/location-eden/slide/01.png';
import Album2 from '../Images/final-final/location-eden/slide/02.png';
import Album3 from '../Images/final-final/location-eden/slide/03.png';
import Album4 from '../Images/final-final/location-eden/slide/04.png';
import Album5 from '../Images/final-final/location-eden/slide/05.png';
import Album6 from '../Images/final-final/location-eden/slide/06.png';
import Album7 from '../Images/final-final/location-eden/slide/07.png';
import Album8 from '../Images/final-final/location-eden/slide/08.png';
import Album9 from '../Images/final-final/location-eden/slide/09.png';

import Offer1 from '../Images/final-final/location-eden/activity/01.png';
import Offer2 from '../Images/final-final/location-eden/activity/02.png';
import Offer3 from '../Images/final-final/location-eden/activity/03.png';
import Offer4 from '../Images/final-final/location-eden/activity/04.png';
import Offer5 from '../Images/final-final/location-eden/activity/05.png';
import Offer6 from '../Images/final-final/location-eden/activity/06.png';
import Offer7 from '../Images/final-final/location-eden/activity/07.png';
import Offer8 from '../Images/final-final/location-eden/activity/08.png';

import Sign1 from '../Images/mockup/new-icon/logo-eden.png';
import SectionAccomodation from '../Components/SectionAccomodation';
import SectionLocationExplore from '../Components/SectionLocationExplore';

const Eden = ({ contents }) => {
  console.log(contents)
  const storage = urlApi + '/storage/article/';

  const mockup = {
    slide: [
      { row_value: Photo1, detail: <></> }
    ],
    slideSecondary: [
      { row_value: Album1, detail: <></> },
      { row_value: Album2, detail: <></> },
      { row_value: Album3, detail: <></> },
      { row_value: Album4, detail: <></> },
      { row_value: Album5, detail: <></> },
      { row_value: Album6, detail: <></> },
      { row_value: Album7, detail: <></> },
      { row_value: Album8, detail: <></> },
      { row_value: Album9, detail: <></> },
    ],
    slideOffer: [
      { row_value: Offer1, detail: <></> },
      { row_value: Offer2, detail: <></> },
      { row_value: Offer3, detail: <></> },
      { row_value: Offer4, detail: <></> },
      { row_value: Offer5, detail: <></> },
      { row_value: Offer6, detail: <></> },
      { row_value: Offer7, detail: <></> },
      { row_value: Offer8, detail: <></> },
    ]
  }

  return (
    <>
      <SectionCarousel showNav={false} showIndicator={false} showCaretDown={false} mockup={mockup.slide} />
      <section className='eden custom-header position-relative location-header'>
        <Container fluid className='mb-5 mb-xl-0'>
          <Row>
            <Col className='text-center'>
              <img src={Sign1} className='location-sign' alt="sign" />
            </Col>
          </Row>
          <Row className='mb-4 mb-xl-5'>
            <Col className='text-center px-5'>
              <div className="fs-custom-1 fw-bold pt-5 mb-5 text-uppercase tx-color-dark tx-spacing-10 lh-1">Eden - Chiang Mai</div>
              <p className='fs-custom-5 lg-12 mb-3 mb-xl-4'>A sanctuary that transports you to a private garden reminiscent of Biblical<br /> times. With only 2 exclusive units, this hidden gem unveils a tranquil<br /> oasis where a small pond glistens under your gaze,<br /> framed by the majestic Doi Suthep mountains as a breathtaking backdrop.</p>
              <p className='fs-custom-5 lg-12'>Tailored for both intimate nature getaways and small group gatherings,<br /> CAMP CAYLA - EDEN offers an escape like no other. Nestled conveniently near the city center,<br /> it strikes the perfect balance, allowing you to explore the vibrant heart of the city<br /> with ease. Immerse yourself in the local charm, as nearby cafes,<br /> renowned temples, and hipster markets await, inviting you to uncover the city's captivating<br /> blend of tradition and contemporary allure.</p>
            </Col>
          </Row>
        </Container>
        <Container fluid className='px-5 px-xl-0'>
          <Row className='mb-1 mb-xl-2'>
            <Col xl={{ span: 8, offset: 2 }}>
              <SectionCarousel showNav={false} showIndicator={true} showCaretDown={false} mockup={mockup.slideSecondary} />
            </Col>
          </Row>
          <Row className='mb-1 mb-xl-2'>
            <Col xl={{ span: 8, offset: 2 }}>
              <a href='https://maps.app.goo.gl/Mt94YwBMKxHeyiNTA?g_st=il' target='_blank'>
                <img src={Map} className='w-100' alt="Pic" />
              </a>
            </Col>
          </Row>
          <Row className='mb-5'>
            <Col xl={{ span: 8, offset: 2 }}>
              <section className='mb-home-accom'>
                <Container fluid className='text-center'>
                  <SectionAccomodation showHeader={false} />
                </Container>
              </section>
            </Col>
          </Row>
        </Container>

        <Container fluid className='bg-color-primary pt-xl-5 pb-xl-5'>
          <Container>
            <Row>
              <Col xl={7} className='px-4 pe-xl-5 order-2 order-xl-1'>
                <div className='pt-xl-5 pb-xl-5 location-offer-slide'>
                  <SectionCarousel showNav={false} showIndicator={true} showCaretDown={false} mockup={mockup.slideOffer} bgClass={'bg-transparent'} moreClass={'custom-ratio-slide'} indicatorClass={'location-indicator'} />
                </div>
              </Col>
              <Col xl={5} className='px-5 px-xl-0 order-1 order-xl-2'>
                <div className="fs-custom-3 fw-bold mb-4 mt-5 mt-xl-5 text-uppercase tx-color-secondary tx-spacing-10 lh-1">Activities<br /> and Offers</div>
                <p className='fs-custom-5 text-white lg-12'>Neighbouring CAMP CAYLA - EDEN just a few footsteps away you find "Baan Kang Wat", an artisan village offering unique local art and crafts, creative activities and many beautiful details to explore for the whole family. As a nearby cultural sight we recommend to visit Wat Umong, one of the oldest temples in Northern Thailand. It is famous for its underground tunnels. For creature comforts you can find beautiful cafés and places to dine just in walking distance. We can highly recommend "Lamour Café" which offers delicious food and beverages in a wonderful surrounding with excellent service and quality. If you rather enjoy the beautiful scenery and relaxing comfort at your private camp we provide two choices of breakfast (Thai & Western) and a BBQ Dinner Set for your personal grilling experience right next to the camps own waterfall.</p>
              </Col>
            </Row>
          </Container>
        </Container>

        <Container fluid>
          <Row>
            <Col lg={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }} className="px-5 px-xl-0 pb-0 pt-5 mb-0 mt-5 mb-xl-0 px-lg-4">
              <div className='embed-video-container mt-5'>
                <iframe width="956" height="538" src="https://www.youtube.com/embed/dM61BJyESXU"
                  title="CAMP CAYLA - EDEN" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; webshare" allowfullscreen></iframe>
              </div>
            </Col>
          </Row>
        </Container>

      </section>

      <SectionLocationExplore current={'/Eden'} />
    </>
  )
}

Eden.defaultProps = {
  contents: {
    lang: 'th',
    content: {
      contents: []
    }
  },
  path: '',
  lang: 'th',
  slides: [],
  slide_events: []
}

export default Eden