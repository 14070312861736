import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { urlApi } from '../Constants/Global';
import Logo from '../Images/logo/logo.png';

const PDPAPopup = ({ showPopup }) => {
  const [show, setShow] = useState(showPopup);

  useEffect(() => {
    setShow(showPopup);
  }, [showPopup]);

  const clickHandler = () => {
    // set pdpa session
    const fullPath = window.location.pathname;
    const pathSplit = fullPath.split('/');
    const pathLength = pathSplit.length;

    var url = (pathLength === 3 ? '../' : '') + urlApi + '/?module=web&call=popup-pdpa-change';
    setShow(false);

    fetch(url, {
      method: 'POST'
    })
      .then(res => res.json())
      .then(result => {
        console.log("clickHandler", result);
      });
  }

  return (
    <div className={`pdpa-welcome-popup p-4 px-3 ${show ? 'd-block' : 'd-none'}`}>
      <div className='d-block d-lg-flex align-items-center'>
        <div className='flex-fill px-3 pb-4 pb-lg-0'>
          <img src={Logo} className="logo" alt="Logo" />
        </div>
        <div className='flex-fill px-3 pb-4 pb-lg-0'>
          This website setup "cookies" to your system in order to improve your experience while navigating our website and to help us develop or improve the quality of our website. Please find more information in our <Link to="/Content/PrivacyPolicy">Privacy Policy</Link> and <Link to='/Content/RefundAndCancellation'>Refund and Cancellations</Link>
        </div>
        {/* <div className='flex-fill text-nowrap px-3 fw-bold pb-4 pb-lg-0 text-end text-lg-center'>
          Cookies Preferences
        </div> */}
        <div className='flex-fill text-nowrap px-3 text-end text-lg-center'>
          <Button className='me-2 px-3 border border-0 rounded-pill fw-bold' onClick={clickHandler}>Reject</Button>
          <Button className='px-3 border border-0 rounded-pill fw-bold' onClick={clickHandler}>Accept</Button>
        </div>
      </div>
    </div>
  )
}

PDPAPopup.defaultProps = {
  showPopup: false
}

export default PDPAPopup