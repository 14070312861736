import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SectionSpace from '../Components/SectionSpace';

const PrivacyPolicy = () => {
  return (
    <>
      <SectionSpace height={'90px'} />
      <section className='privacy-policy'>
        <Container fluid className='px-5 px-xl-0'>
          <Row>
            <Col md={{ span: 4, offset: 4 }}>
              <div className='fs-custom-3 fw-bold mb-3 tx-color-primary tx-spacing-10'>Privacy Policy</div>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 4, offset: 4 }}>
              <p>Camp Cayla Co., Ltd. and its affiliates including persons involved in the processing of personal data on requests, orders or bookings or on behalf of the Company (hereinafter referred to as the “Company”) is committed to protect the privacy of our guests, partners, and business partners (hereinafter referred to as the “you” or “data subject”) from collecting, using and/or disclosing (hereinafter referred to as the “Data Processing”) in accordance with the Personal Data Protection Act B.E. 2562. As a result, the Company is providing this notice to notify users of their rights and obligations, as well as the terms and conditions for collecting, using, and disclosing personal data.</p>

              <b>Personal data collected by the Company</b>
              <p>Personal data means any individual data which allows to identify you directly or indirectly. The Company will collect and save personal data using lawful methods and only to the degree necessary for the Company’s operational purposes, such as:</p>
              <ol>
                <li>Personal data: title, name, surname, age, gender, date of birth, identification number, passport number, driver’s license number, signature, etc.</li>
                <li>Contact data: address, phone number, e-mail, etc.</li>
                <li>Government documents data: Copy of ID card, copy of passport, copy of driver’s license, copy of travel visa, alien identification number, arrival-departure ticket (T.M.6) etc.</li>
                <li>Financial data: Credit card information, etc.</li>
                <li>Data collected by the Company or its automated systems from various devices of the Company, online services or digital communication: IP address, cookies, user behaviour, user history, voice, photo, video, social media, chat, geolocation, etc.</li>
              </ol>

              <p>The Company will collect personal data with the prior consent of the personal data subject except in the following cases:</p>
              <ol>
                <li>To perform the contract in the case of collecting, using or disclosing personal data for the necessity of providing services or contracts between the data subject and the Company.</li>
                <li>To prevent or suppress harm to life, body or health.</li>
                <li>To obey the law.</li>
                <li>To the legitimate interests of the Company if necessary for the legitimate interests of the Company’s operations. The Company will consider the rights of the data subject as important. For example, fraud prevention, network security, protection of the rights, freedoms and interests of all data subjects, etc.</li>
                <li>To conduct research or statistics in the case of historical documents or archives for the public benefit or related to research studies or statistics in order to provide appropriate safeguards to protect the rights and freedoms of data subjects.</li>
                <li>To perform state missions if necessary for the performance of duties for the public interest or the performance of duties under the state powers assigned by the Company.</li>
              </ol>

              <b>Data source</b>
              <ol>
                <li>Information directly from the data subject that you have provided in the activities. For example, making a booking or reservation, using a service, answering a questionnaire, subscribing to information, participating in marketing activities through a website, application or other, entering and exiting the Company’s premises, or any other communication between the Company and you.</li>
                <li>Information from automated systems such as CCTV footage.</li>
                <li>Information from other sources such as public information, information from partner agencies, government agencies or affiliates, as well as other personal information provided.</li>
              </ol>

              <b>Purpose of processing personal data</b>
              <p>The Company may use personal information for the following purposes or for other purposes while collecting information or obtaining your consent after the Company collects the information.</p>
              <ol>
                <li>To enter into a contract or perform duties under a contract between the Company and the data subject or a contract between the Company and a third party for the benefit of the data subject.</li>
                <li>To authenticate and verify customer information when using the Company’s services or exercising legal rights.</li>
                <li>To provide information and recommend products, services or public relations for marketing promotions or benefits through contact channels provided by the data subject.</li>
                <li>To deliver products and provide services.</li>
                <li>To evaluate and improve the business to improve the quality of products and services</li>
                <li>To carry out accounting and financial activities such as auditing, invoicing and collection, issuing tax invoices and proof of transactions required by law.</li>
                <li>To be beneficial according to the Company’s law, such as recording complaints through the Call Center system, recording images through CCTV cameras, recording entering and exiting the Company’s premises.</li>
                <li>To abide by the rules and regulations of the Company.</li>
                <li>To apply for a job as an employee and/or for any other related purposes.</li>
                <li>To investigate and comply with the laws, regulations or legal duties of the Company.</li>
                <li>To respond to your requests such as after-sales service, complaint handling.</li>
                <li>To facilitate and offer various benefits to service users.</li>
                <li>To perform marketing, promotional and customer relationship purposes such as sending promotional information, products and services, promotional items and affiliate businesses.</li>
                <li>Customer satisfaction surveys, market research and statistical analysis to improve products and services or innovate new products and services.</li>
                <li>Other purposes with your express consent.</li>
              </ol>
              
              <b>Personal data transfer and disclosure</b>
              <p>The Company will not disclose and transmit your personal information to external parties unless with your explicit consent or in the following cases:</p>
              <ol>
                <li>Affiliates or business partners to perform duties under a contract between the Company and the data subject or a contract between the Company and a third party for the benefit of the data subject.</li>
                <li>Agents or partners who provide services to the Company or on behalf of the Company such as transport service providers, document storage and destruction service providers, marketing activities and advertising media contractors, developers and administrators of security and information technology systems, auditors, lawyers, legal or tax advisors, etc.</li>
                <li>Banks and payment service providers such as credit or debit card companies, etc.</li>
              </ol>
              <p>In this regard, the Company will provide a personal data processing agreement as required by law.</p>
              <ol>
                <li>In order to achieve the purposes outlined in this Privacy Policy, the Company may be required to disclose or share information only as necessary to partners, service providers or external entities.</li>
                <li>The Company may disclose or share personal information with its affiliates only for the purposes stated in this Privacy Policy.</li>
                <li>The law or legal process requires disclosure to an official, government official or competent authority in order to comply with a legal order or request.</li>
              </ol>
              
              <b>Transferring or sending data to foreign countries</b>
              <p>In some cases, the Company may send the data subject’s information to foreign countries such as in the case of the Company communicating with service recipients or external agencies located abroad. If one of the following circumstances is met, the Company will send the data subject’s information abroad in either way:</p>
              <ol>
                <li>The destination country has determined by the Personal Data Protection Board that there is adequate personal data protection.</li>
                <li>Foreign entities are subject to a personal data protection policy that has been reviewed and certified by the Office of the Personal Data Protection Commission.</li>
                <li>The Company and foreign agencies can put in place appropriate personal data protection measures to enforce the rights of the personal data subject. It includes effective legal remedies in accordance with the rules and procedures announced by the Personal Data Protection Commission. For example, standard contract clauses, codes of practice, certified standards, etc.</li>
                <li>It is necessary for the exercise of legal rights.</li>
                <li>You are aware of inadequate personal protection standards of the destination country or international organization that has received personal data.</li>
                <li>It is necessary for the performance of the contract to which you are the party or for your request before entering into that contract.</li>
                <li>It is an action under a contract between the Company and another person or juristic person for your benefit.</li>
                <li>To prevent or suppress harm to the life, body, or health of you or others when you are unable to give consent at that time.</li>
                <li>It is necessary for the mission of the important public interest.</li>
              </ol>

              <b>Protection of personal data</b>
              <p>The Company has established and/or used a personal data storage system with appropriate mechanisms and techniques, as well as security measures in accordance with the law on personal data protection and related laws. It includes restricting access to your personal information from employees and agents of the Company to prevent your personal information from being disclosed, destroyed or accessed without permission.</p>

              <b>Collection period</b>
              <p>The Company will only collect your personal data for as long as it is necessary for the processing for the purposes of this policy unless it is necessary to keep personal data for any other reason. For example, in order to comply with the law or investigate cases of disputes, the Company may be required to retain information for longer than specified periods.</p>

              <b>Rights of personal data subject</b>
              <p>You can exercise the rights as required by law and as set forth in this notice as follows:</p>
              <ol>
                <li>Right to withdraw consent, you have the right to withdraw your consent at any time unless restricted by law or contractually beneficial to you. However, the withdrawal of consent will not affect the processing of personal data that you have previously given consent to the Company.</li>
                <li>The right to request access and obtain a copy of personal data, unless the company has the right to refuse your request by law or court order or your request will have consequences that may harm the rights and freedoms of others.</li>
                <li>The right to request that personal information be updated, correct, complete and not cause misunderstanding. However, the Company can amend the above even if you do not request it.</li>
                <li>The right to request removal or non-identification of an individual in the following cases:
                  <ol>
                    <li>When personal data is no longer necessary to keep for the purposes of collecting, using or disclosing personal data.</li>
                    <li>When you withdraw your consent to the collection, use or disclosure of personal information and the Company has no legal authority to collect, use or disclose personal information further.</li>
                    <li>When you object to the collection, use or disclosure of personal data and the Company has no legal authority to refuse it, unless the Company has legitimate grounds to decline your request.</li>
                  </ol>
                </li>
                <li>The right to obtain or request the transmission of your personal data in the event that the Company has made the personal data in a readable or generally usable format by means of automated tools or equipment to use or disclose personal information by automatic means unless the technical condition is impracticable or for the benefit of the public or statutory duty or the exercise of infringement against the rights or freedoms of others.</li>
                <li>Right to objection, you have the right to object to the collection, use or disclosure of personal data at any time.</li>
                <li>
                  The right to request that the use of personal data be restricted in the following circumstances:
                  <ol>
                    <li>When the Company is in the process of reviewing your request to amend your personal data to be accurate, current, complete and not cause misunderstandings.</li>
                    <li>Personal data that must be deleted or destroyed.</li>
                    <li>When such personal data is no longer necessary because you are obliged to request the retention of your personal data in order to establish a legal claim, compliance or exercising legal claims or countering legal claims.</li>
                    <li>When the Company is in the process of proving its right to refuse a request to object to the collection, use or disclosure of personal data.</li>
                  </ol>
                </li>
                <li>If the Company violates or fails to comply with any regulations, you have the right to notify the expert committee under the Personal Data Protection Act B.E. 2562</li>
              </ol>
              <p>If you have any questions or would like to correct, delete the data, exercise your rights or make any other contact regarding your data, please inform us through the contact channels below.</p>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 4, offset: 4 }}>
              <b>Camp Cayla Co., Ltd.</b>
              <p>52/15 Changphueak Road<br/>
                Sripoom, Mueng Chiangmai<br/>
                Chiangmai 50200<br/>
                Tel.: +66-(0)62-398 74 96<br/>
                Email: hello@campcayla.com</p>
              <p>Please provide the following additional information for the exercise of the rights of the data subject:</p>
              <ul>
                <li>Name-surname, ID card number/passport number</li>
                <li>Inquiries about personal data or legal rights</li>
                <li>Phone number, address, and e-mail address that can be contacted back.</li>
              </ul>
              <p>This privacy notice will come into effect on the 1st of February, 2024.</p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default PrivacyPolicy