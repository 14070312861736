import React from 'react';

const ContentStyle = () => {
  // Define the styles as an object
  const styles = {
    '.HTML-Displayer': {
      'line-height': '1'
    },
    '.HTML-Displayer .ql-align-right': {
      'text-align': 'right', 
      'margin-bottom': '0'
    },
    '.HTML-Displayer .ql-align-center': {
      'text-align': 'center', 
      'margin-bottom': '0'
    },
    '.HTML-Displayer .ql-align-left': {
      'text-align': 'left', 
      'margin-bottom': '0'
    },
    '.HTML-Displayer p': {
      'margin-bottom': '0', 
      'line-height': '1'
    }
  };

  // Create CSS classes dynamically
  let styleSheet = '';
  for (const className in styles) {
    styleSheet += `${className} {`;
    for (const property in styles[className]) {
      styleSheet += `${property}: ${styles[className][property]};`;
    }
    styleSheet += `}\n`;
  }

  return (
    <style>{styleSheet}</style>
  );
};

export default ContentStyle;
