import React from 'react';
import { urlApi } from '../Constants/Global';
import SectionCarousel from '../Components/SectionCarousel';
import { Container, Row, Col } from 'react-bootstrap';
import Pic1 from '../Images/mockup/location/photo-rayong-1.png';

import Photo1 from '../Images/final/8-coasta/head.png';
import Map from '../Images/final-final/location-coasta/map.png';

import Album1 from '../Images/final-final/location-coasta/slide/01.png';
import Album2 from '../Images/final-final/location-coasta/slide/02.png';

import Offer1 from '../Images/final-final/location-coasta/activity/01.png';
import Offer2 from '../Images/final-final/location-coasta/activity/02.png';
import Offer3 from '../Images/final-final/location-coasta/activity/03.png';

import Sign1 from '../Images/final/logo/coasta.png';
import SectionAccomodation from '../Components/SectionAccomodation';
import SectionLocationExplore from '../Components/SectionLocationExplore';

const Rayong = ({ contents }) => {
  console.log(contents)
  const storage = urlApi + '/storage/article/';

  const mockup = {
    slide: [
      { row_value: Photo1, detail: <></> }
    ],
    slideSecondary: [
      { row_value: Album1, detail: <></> },
      { row_value: Album2, detail: <></> },
    ],
    slideOffer: [
      { row_value: Offer1, detail: <></> },
      { row_value: Offer2, detail: <></> },
      { row_value: Offer3, detail: <></> },
    ]
  }

  return (
    <>
      <SectionCarousel showNav={false} showIndicator={false} showCaretDown={false} mockup={mockup.slide} />
      <section className='custom-header position-relative location-header'>
        <Container fluid className='content-top'>
          <Row>
            <Col className='text-center'>
              <img src={Sign1} className='location-sign' alt="sign" />
            </Col>
          </Row>
          <Row className='mb-4 mb-xl-5'>
            <Col className='text-center px-5'>
              <div className="fs-custom-1 fw-bold pt-5 mb-5 text-uppercase tx-color-dark tx-spacing-10 lh-1">COASTA - RAYONG</div>
              <p className='fs-custom-5 lg-12 mb-3 mb-xl-4'>COASTA is our newest camping destination nestled along the pristine beachfront <br />
                of Rayong. Offering a unique blend of luxury and adventure, our site features glamping camps, <br />
                on-grid and off-grid camping grounds, and slots for car camping. COASTA not only offers <br />
                a beautiful beachfront, but also a small lake and community farming which invites our guests <br />
                for fishing or picnics. Immerse yourself in the tranquil beauty of nature while enjoying <br />
                the convenience of modern amenities. Whether you seek relaxation by the sea or crave <br />
                an adrenaline-fueled escape, our beachfront location promises unforgettable <br />
                experiences for all adventurers. Come discover the perfect balance of <br />
                comfort and wilderness at our Rayong camping haven.</p>
            </Col>
          </Row>
        </Container>
        <Container fluid className='px-5 px-xl-0'>
          <Row className='mb-1 mb-xl-2'>
            <Col xl={{ span: 8, offset: 2 }}>
              <SectionCarousel showNav={false} showIndicator={true} showCaretDown={false} mockup={mockup.slideSecondary} />
            </Col>
          </Row>
          <Row className='mb-1 mb-xl-2'>
            <Col xl={{ span: 8, offset: 2 }}>
              <img src={Map} className='w-100' alt="Pic" />
            </Col>
          </Row>
          <Row className='mb-5'>
            <Col xl={{ span: 8, offset: 2 }}>
              <section className='mb-home-accom'>
                <Container fluid className='text-center'>
                  <SectionAccomodation showHeader={false} />
                </Container>
              </section>
            </Col>
          </Row>
        </Container>

        <Container fluid className='bg-color-primary pt-xl-5 pb-xl-5'>
          <Container>
            <Row>
              <Col xl={7} className='px-4 pe-xl-5 order-2 order-xl-1'>
                <div className='pt-xl-5 pb-xl-5 location-offer-slide'>
                  <SectionCarousel showNav={false} showIndicator={true} showCaretDown={false} mockup={mockup.slideOffer} bgClass={'bg-transparent'} moreClass={'custom-ratio-slide'} indicatorClass={'location-indicator'} />
                </div>
              </Col>
              <Col xl={5} className='px-5 px-xl-0 order-1 order-xl-2'>
                <div className="fs-custom-3 fw-bold mb-4 mt-5 mt-xl-5 text-uppercase tx-color-secondary tx-spacing-10 lh-1">Activities<br /> and Offers</div>
                <p className='fs-custom-5 text-white lg-12' style={{ marginBottom: '30px' }}>Besides the resorts own beach, the surrounding area offers many beautiful beaches such as the stunning Laem Mae Phim Beach among others. From the nearby pier, boats will bring you to explore the beautiful national park islands in the area: Ko Ku Dee or Ko Thalu with its breath-taking rock arch - you can swim, snorkel, and climb on top of the rock arch or simply relax and enjoy the stunning scenery. From the Raksamae Bridge you can watch and explore the area and get more knowledge about the local ecosystems in the mangrove forest learning center. At the Rayong Botanic Garden you can walk on paths through the beautiful and unique mangrove landscape or rent a bicycle or kayak to discover this hidden gem. Visit the nearby Sunthorn Phu memorial and dive into Thailand's cultural history. Sunthorn Phu is Thailand's best-known royal poet and was honoured by UNESCO as a great world poet. HTMS Prasae Memorial is a retired battleship that can be visited nearby. Kids will love this place being able to climb all about the ship and pretend to be the captain.</p>
              </Col>
            </Row>
          </Container>
        </Container>

        <Container fluid>
          <Row>
            <Col lg={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }} className="px-5 px-xl-0 pb-0 pt-5 mb-0 mt-5 mb-xl-0">

            </Col>
          </Row>
        </Container>

      </section>

      <SectionLocationExplore current={'/Rayong'} />
    </>
  )
}

Rayong.defaultProps = {
  contents: {
    lang: 'th',
    content: {
      contents: []
    }
  },
  path: '',
  lang: 'th',
  slides: [],
  slide_events: []
}

export default Rayong