import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { urlApi } from '../../Constants/Global';

const SectionPartner = ({ contents }) => {
  return (
    <>
        <Container>
          <Row>
            {contents.map((item, index) => (
            <Col key={index} md={3} className="p-5">
              <img src={`${urlApi}/storage/media/${item.set1}`} alt="Partner" className='w-100' />
            </Col>
            ))}
          </Row>
        </Container>
    </>
  )
}

SectionPartner.defaultProps = {
  contents: []
}

export default SectionPartner