import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { urlApi } from '../Constants/Global';
import { Container, Row, Col } from 'react-bootstrap';
import { RxDotFilled as Dot } from "react-icons/rx";
import { RxPlus, RxMinus } from "react-icons/rx";
import SectionCarousel from '../Components/SectionCarousel';
import SectionAccomodation from '../Components/SectionAccomodation';

import Photo1 from '../Images/mockup/accom/head-glamping.png';

import Pic1 from '../Images/mockup/accom/s-glamping-1.png';
import Pic2 from '../Images/mockup/accom/l-glamping-1.png';

import s1 from '../Images/final-final/accom-glamping/base-s/01.png';
import s2 from '../Images/final-final/accom-glamping/base-s/02.png';
import s3 from '../Images/final-final/accom-glamping/base-s/03.png';
import s4 from '../Images/final-final/accom-glamping/base-s/04.png';
import s5 from '../Images/final-final/accom-glamping/base-s/05.png';
import s6 from '../Images/final-final/accom-glamping/base-s/06.png';

import l1 from '../Images/final-final/accom-glamping/suite-l/01.png';
import l2 from '../Images/final-final/accom-glamping/suite-l/02.png';
import l3 from '../Images/final-final/accom-glamping/suite-l/03.png';
import l4 from '../Images/final-final/accom-glamping/suite-l/04.png';
import l5 from '../Images/final-final/accom-glamping/suite-l/05.png';
import l6 from '../Images/final-final/accom-glamping/suite-l/06.png';
import l7 from '../Images/final-final/accom-glamping/suite-l/07.png';
import l8 from '../Images/final-final/accom-glamping/suite-l/08.png';
import l9 from '../Images/final-final/accom-glamping/suite-l/09.png';

import icon1 from '../Images/update2/accom-icon/01.png';
import icon2 from '../Images/update2/accom-icon/02.png';
import icon3 from '../Images/update2/accom-icon/03.png';
import icon4 from '../Images/update2/accom-icon/04.png';
import icon5 from '../Images/update2/accom-icon/05.png';
import icon6 from '../Images/update2/accom-icon/06.png';
import icon7 from '../Images/update2/accom-icon/07.png';
import icon8 from '../Images/update2/accom-icon/08.png';
import icon9 from '../Images/update2/accom-icon/09.png';
import icon10 from '../Images/update2/accom-icon/10.png';
import icon11 from '../Images/update2/accom-icon/11.png';
import icon12 from '../Images/update2/accom-icon/12.png';
import icon13 from '../Images/update2/accom-icon/13.png';

const Glamping = ({ contents }) => {
  console.log(contents)
  const storage = urlApi + '/storage/article/';

  const [showDetail1, setShowDetail1] = useState(false);
  const [showDetail2, setShowDetail2] = useState(false);

  const toggleDetail1 = () => {
    setShowDetail1(!showDetail1);
  };

  const toggleDetail2 = () => {
    setShowDetail2(!showDetail2);
  };

  const mockup = {
    slide: [
      { row_value: Photo1, detail: <></> }
    ],
    campS: [
      { row_value: s1 },
      { row_value: s2 },
      { row_value: s3 },
      { row_value: s4 },
      { row_value: s5 },
      { row_value: s6 },
    ],
    campL: [
      { row_value: l1 },
      { row_value: l2 },
      { row_value: l3 },
      { row_value: l4 },
      { row_value: l5 },
      { row_value: l6 },
      { row_value: l7 },
      { row_value: l8 },
      { row_value: l9 },
    ]
  }

  const setDynamicHeader = () => {
    const windowWidth = window.innerWidth;
    const headerElement = document.querySelector(".header-lg");
    const topContainer = document.querySelector(".top-layer");

    // Check if the header element and topContainer exist
    if (headerElement && topContainer) {
      const headerHeight = parseInt(getComputedStyle(headerElement).height);

      // Assuming windowWidth is defined somewhere in your code
      if (windowWidth <= 1199) {
        if (headerHeight > 35) {
          topContainer.style.marginTop = '-80px';
        } else {
          topContainer.style.marginTop = '-50px';
        }
      }
    }
  };

  const setDynamicHeight = () => {
    const windowWidth = window.innerWidth;
    const container = document.querySelectorAll('.layer-container');

    container.forEach(element => {
      const children = element ? element.children : [];

      let maxHeight = 0;

      const updateMaxHeight = () => {
        for (let i = 0; i < children.length; i++) {
          const childHeight = children[i].offsetTop + children[i].offsetHeight;
          if (childHeight > maxHeight) {
            maxHeight = childHeight;
          }
        }

        console.log(maxHeight);

        if (windowWidth <= 1199) {
          // in case of mobile size just disable it.
          element.style.height = 'unset';
        } else {
          element.style.height = maxHeight + 'px';
        }
      };

      // Check if all images are loaded before calculating maxHeight
      const images = element.querySelectorAll('img');
      const imageCount = images.length;
      let loadedImages = 0;

      const imageLoadedHandler = () => {
        loadedImages++;
        if (loadedImages === imageCount) {
          updateMaxHeight();
        }
      };

      images.forEach(image => {
        if (image.complete) {
          // Image is already loaded
          imageLoadedHandler();
        } else {
          // Add a load event listener to each image
          image.addEventListener('load', imageLoadedHandler);
        }
      });
    });
  };

  useEffect(() => {
    setDynamicHeight();
    setDynamicHeader();

    // Event listener for window resize
    const handleResize = () => {
      setDynamicHeight();
      // You may also want to call setDynamicHeader() here if needed
    };

    // Attach event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>
      <SectionCarousel showNav={false} showIndicator={false} showCaretDown={false} mockup={mockup.slide} />
      <section className='glamping custom-header position-relative'>
        <Container fluid>
          <div className='layer-container position-xl-relative'>
            <Row className='layer-item'>
              <Col className='text-center'>
                <div className='position-relative header-lg font-header text-uppercase tx-color-secondary'>Glamping</div>
                <div className='position-relative subheader-lg tx-spacing-15 text-uppercase'>New Impressions</div>
              </Col>
            </Row>
          </div>
          <div className='layer-container position-static position-xl-relative top-layer'>
            <Row className='layer-item position-static position-xl-absolute' style={{ zIndex: 2 }}>
              <Col className='px-0' xl={{ span: 7, offset: 5 }}>
                <div className='layer-content-text top-header'>
                  <div className='fs-custom-2 fw-bold tx-color-secondary text-uppercase tx-spacing-10 mb-3'>Glamping Suite</div>
                  <p className='fs-custom-5 lh-12 text-white'>Escape to the spacious comfort with our Glamping Suite units, spanning an expansive 100sqm. These indulgent accommodations consist of a luxurious one-bedroom tent and a separate living room tent, which can easily be transformed to accommodate additional guests. The spacious private bathroom with separate his and her sinks and a walk-in rain shower offer a luxurious experience. Embrace the fusion of modern conveniences and the untamed beauty of nature, as each unit has a large deck with a spacious daybed overlooking the beautiful landscape.</p>
                  <p className='fs-custom-5 lh-12 text-white'>Amidst this simplistic elegance, you will find all the essential modern amenities, including a complementary mini bar with refrigerator, Wi-Fi internet, a smart TV and air conditioning. Take this opportunity to not only reconnect with nature but also to detach from the hectic pace of city life. Your stay in our Glamping Suite rooms promises an enchanting blend of relaxation, adventure, and the timeless allure of the great outdoors.</p>
                </div>
              </Col>
            </Row>
            <Row className='layer-item position-static position-xl-relative' style={{ zIndex: 4 }}>
              <Col className='px-5' xl={7}>
                <div className='layer-content-slide ps-xl-5'>
                  <SectionCarousel showNav={false} showIndicator={true} showCaretDown={false} mockup={mockup.campL} bgClass={'bg-transparent'} moreClass={'custom-ratio-slide'} indicatorClass={'glamping-indicator-primary'} />
                </div>
              </Col>
            </Row>
          </div>
          <Container className='mt-xl-5 mb-xl-0 pt-xl-5 pb-xl-0'>
            <Row className='pt-5'>
              <Col className='px-5 px-xl-0 fs-custom-5'>
                <p className='fw-bold'>100 sqm, 2-4 adults, Bedroom + livingroom tent & private bathroom</p>
                <b>Detail</b>
                <ul className='facility-list'>
                  <li className='d-flex d-xl-inline-block'><Dot className='me-3' size={20} style={{ flex: 'none' }} />Air condition in bed- & livingroom tents</li>
                  <li className='d-flex d-xl-inline-block'><Dot className='me-3' size={20} style={{ flex: 'none' }} />Covered terrace with large daybed</li>
                  <li className='d-flex d-xl-inline-block'><Dot className='me-3' size={20} style={{ flex: 'none' }} />13.5 sqm bathroom with rain shower and his & her sinks</li>
                  <li className='d-flex d-xl-inline-block'><Dot className='me-3' size={20} style={{ flex: 'none' }} />Outdoor wooden bathtub</li>
                </ul>
                <h6 className='fw-bold'>
                  Additional details
                  <span className='float-end px-2' onClick={toggleDetail1}>
                    {showDetail1 ? (<RxMinus />) : (<RxPlus />)}
                  </span>
                </h6>
                {showDetail1 && (
                  <ul className='facility-list'>
                    <li className='d-flex d-xl-inline-block'><img src={icon1} className='me-3' />Queensize bed + 1 optional QB</li>
                    <li className='d-flex d-xl-inline-block'><img src={icon8} className='me-3' />Kettle</li>
                    <li className='d-flex d-xl-inline-block'><img src={icon2} className='me-3' />Smart TV</li>
                    <li className='d-flex d-xl-inline-block'><img src={icon9} className='me-3' />Hair dryer</li>
                    <li className='d-flex d-xl-inline-block'><img src={icon3} className='me-3' />Highspeed Wifi</li>
                    <li className='d-flex d-xl-inline-block'><img src={icon10} className='me-3' />Umbrellas</li>
                    <li className='d-flex d-xl-inline-block'><img src={icon4} className='me-3' />Coffee drip set</li>
                    <li className='d-flex d-xl-inline-block'><img src={icon11} className='me-3' />Glass bottled drinking water</li>
                    <li className='d-flex d-xl-inline-block'><img src={icon5} className='me-3' />Safety deposit box</li>
                    <li className='d-flex d-xl-inline-block'><img src={icon12} className='me-3' />Bluetooth speaker</li>
                    <li className='d-flex d-xl-inline-block'><img src={icon6} className='me-3' />Complementary mini bar & snacks</li>
                    <li className='d-flex d-xl-inline-block'><img src={icon13} className='me-3' />Optional BBQ</li>
                    <li className='d-flex d-xl-inline-block'><img src={icon7} className='me-3' />Refrigerator</li>
                  </ul>
                )}
              </Col>
            </Row>
          </Container>
          <Container fluid className='mt-xl-5 mb-xl-0 pt-xl-0 pb-xl-0 px-0 px-xl-0'>
            <Row className='bg-color-primary content-2 px-0 px-xl-5'>
              <Col className="px-5 px-xl-0 pb-5">
                <Row className='pb-0 pb-xl-5'>
                  <Col xl={5} className='pe-2 pe-xl-5 ps-2 ps-xl-5 order-1 order-xl-1 glamping-text-layout'>
                    <div className='mt-3 mt-xl-5 fs-custom-2 fw-bold tx-color-secondary text-uppercase tx-spacing-10 mb-3'>Glamping Base</div>
                    <p className='fs-custom-5 lh-12 text-white'>Indulge in the serenity of our Glamping Base units, providing a generous 70 sqm haven nestled amidst the natural landscape. Immerse yourself in the perfect blend of modern comforts and tranquil outdoor beauty. These private retreats are thoughtfully designed and consist of a plush one-bedroom tent and a private bathroom with separate his and her sinks and a walk-in rain shower for a luxurious experience.</p>
                    <p className='fs-custom-5 lh-12 text-white'>Step into a world where contemporary amenities seamlessly merge with the lush landscapes that surround you. Each Glamping Base includes a complementary mini bar with refrigerator, Wi-Fi internet, a smart TV and air conditioning. The spacious daybed invites you to relax and experience the breathtaking views that unfold right in front of you.</p>
                    <Link to='/Booking'>
                      <div className='d-inline-block d-xl-none btn-booknow text-center tx-color-primary bg-color-secondary mt-3 p-2 px-5 fw-bold btn-booknow text-uppercase'>
                        Book Now
                      </div>
                    </Link>
                  </Col>
                  <Col xl={7} className="text-end p-0 px-2 px-xl-0 pe-xl-5 order-2 order-xl-2 slide-object position-relative glamping-slide-layout">
                    <div className='mb-5'>
                      <SectionCarousel showNav={false} showIndicator={true} showCaretDown={false} mockup={mockup.campS} bgClass={'bg-transparent'} moreClass={'custom-ratio-slide'} indicatorClass={'glamping-indicator-tertiary'} />
                    </div>
                    <Link to='/Booking'>
                      <div className='d-none d-xl-inline-block btn-booknow text-center tx-color-primary bg-color-secondary mt-5 mb-5 p-2 fw-bold btn-booknow text-uppercase'>Book Now</div>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <Container className='mt-xl-5 mb-xl-0 pt-xl-0 pb-xl-0 px-0 px-xl-5'>
            <Row className='pt-0 pt-xl-5 mt-5 mt-xl-0'>
              <Col className='px-5 px-xl-0 pt-5 pt-xl-0 fs-custom-5 mt-md-5 mt-0'>
                <p className='fw-bold'>70 sqm, 2 adults, Bedroom tent & private bathroom</p>
                <b>Detail</b>
                <ul className='facility-list'>
                  <li className='d-flex d-xl-inline-block'>
                    <Dot className='me-3' size={20} style={{ flex: 'none' }} />Air condition in bedroom tent
                  </li>
                  <li className='d-flex d-xl-inline-block'>
                    <Dot className='me-3' size={20} style={{ flex: 'none' }} />Covered terrace with large daybed
                  </li>
                  <li className='d-flex d-xl-inline-block'>
                    <Dot className='me-3' size={20} style={{ flex: 'none' }} />11.5 sqm bathroom with rain shower and his & her sinks
                  </li>
                </ul>
                <h6 className='fw-bold'>
                  Additional details
                  <span className='float-end px-2' onClick={toggleDetail2}>
                    {showDetail2 ? (<RxMinus />) : (<RxPlus />)}
                  </span>
                </h6>
                {showDetail2 && (
                  <ul className='facility-list'>
                    <li className='d-flex d-xl-inline-block'>
                      <img src={icon1} className='me-3' />Queensize bed + 1 optional QB
                    </li>
                    <li className='d-flex d-xl-inline-block'>
                      <img src={icon8} className='me-3' />Kettle
                    </li>
                    <li className='d-flex d-xl-inline-block'>
                      <img src={icon2} className='me-3' />Smart TV
                    </li>
                    <li className='d-flex d-xl-inline-block'><img src={icon9} className='me-3' />Hair dryer</li>
                    <li className='d-flex d-xl-inline-block'><img src={icon3} className='me-3' />Highspeed Wifi</li>
                    <li className='d-flex d-xl-inline-block'><img src={icon10} className='me-3' />Umbrellas</li>
                    <li className='d-flex d-xl-inline-block'><img src={icon4} className='me-3' />Coffee drip set</li>
                    <li className='d-flex d-xl-inline-block'><img src={icon11} className='me-3' />Glass bottled drinking water</li>
                    <li className='d-flex d-xl-inline-block'><img src={icon5} className='me-3' />Safety deposit box</li>
                    <li className='d-flex d-xl-inline-block'><img src={icon12} className='me-3' />Bluetooth speaker</li>
                    <li className='d-flex d-xl-inline-block'><img src={icon6} className='me-3' />Complementary mini bar & snacks</li>
                    <li className='d-flex d-xl-inline-block'><img src={icon13} className='me-3' />Optional BBQ</li>
                    <li className='d-flex d-xl-inline-block'><img src={icon7} className='me-3' />Refrigerator</li>
                  </ul>
                )}
              </Col>
            </Row>
          </Container>
        </Container>

        <Container>
          <Row>
            <Col lg={{ span: 8, offset: 2 }} className="px-5 px-xl-0 pb-5 pt-0 mb-5 mt-5 mb-xl-0">
              <div className='embed-video-container mt-5'>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/4g8w45WUFoo?si=j3_7_63getvyo_oD" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>
            </Col>
          </Row>
        </Container>

      </section>

      <div className='pt-0 mt-0 pt-xl-0 mt-xl-0 px-5 px-xl-0'>
        <SectionAccomodation current={'Glamping'} showHeader={true} headerText={'Explore More'} sizingXL={{ span: 8, offset: 2 }} />
      </div>

    </>
  )
}

Glamping.defaultProps = {
  contents: {
    lang: 'th',
    content: {
      contents: []
    }
  },
  path: '',
  lang: 'th',
  slides: [],
  slide_events: []
}

export default Glamping