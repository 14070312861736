import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { urlApi } from '../Constants/Global';
import Sign1 from '../Images/final/10-booking/eden.png';
import Sign2 from '../Images/final/10-booking/stellar.png';
import Sign3 from '../Images/final/10-booking/rayong.png';

const SectionBooking = ({ location }) => {
  const storage = urlApi + '/storage/media/';

  return (
    <section className='booking'>
      <Container fluid className='text-center'>
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <div className='fs-custom-3 fw-bold mb-3 text-uppercase tx-color-dark tx-spacing-10'>Please Choose a Location</div>
          </Col>
        </Row>
      </Container>
      <Container className='mt-4 mt-md-5'>
        <Row className='px-5'>

          {location.map((item, index) => (
            <Col xs={6} md={4} className='text-center px-md-5 mb-3 mb-xl-0' key={index}>
              <a href={item.booking_link} target='_blank'>
                <img src={storage + item.header_icon2} className='mb-5 mb-md-0 sign' />
              </a>
            </Col>
          ))}
          
        </Row>
      </Container>
    </section>
  )
}

SectionBooking.defaultProps = {
  location: []
}

export default SectionBooking