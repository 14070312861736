import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Styles/core.css';
import { baseName } from './Constants/Global';
import favicon from './Images/update2/contact/LOGO-CAMP-CAYLA.png';
import SiteHelmet from './Components/SiteHelmet';
import MainMenu from './Components/MainMenu';
import MainFooter from './Components/MainFooter';
import Home from './Pages/Home';
import About from './Pages/About';
import Content from './Pages/Content';
import Article from './Pages/Article';
import ArticleView from './Pages/ArticleView';
import Contact from './Pages/Contact';
import Course from './Pages/Course';
import Event from './Pages/Event';
import News from './Pages/News';
import Booking from './Pages/Booking';
import Glamping from './Pages/Glamping';
import OnGridCamping from './Pages/OnGridCamping';
import OffGridCamping from './Pages/OffGridCamping';
import CarCamping from './Pages/CarCamping';
import Eden from './Pages/Eden';
import Stellar from './Pages/Stellar';
import Coasta from './Pages/Rayong';
import ObjRouteManager from './Components/ObjRouteManager';
import ScrollToTop from './Components/ScrollToTop';
import PDPAPopup from './Components/PDPAPopup';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import RefundAndCancellation from './Pages/RefundAndCancellation';
import Accommodation from './Pages/Accommodation';
import Location from './Pages/Location';

const App = () => {
  const [datas, setDatas] = useState({});

  const routeChangeHandler = (contents) => {
    console.log('routeChangeHandler', contents);
    setDatas(contents);
  }

  return (
    <>
      <BrowserRouter basename={baseName}>
        <ObjRouteManager changeHandler={routeChangeHandler}></ObjRouteManager>
        <ScrollToTop>
          <SiteHelmet favicon={favicon} author="campcayla" title="CAMP CAYLA" />
          <MainMenu
            page={datas.page_name}
            path={datas.page_path}
            navColor={datas.navColor}
            accom={datas.menu_accoms}
            location={datas.menu_locations}
          />
          <Routes>
            <Route path="/" element={<Home contents={datas} />} />
            <Route path="/Accommodation/:slug" element={<Accommodation contents={datas} />} />
            <Route path="/Location/:slug" element={<Location contents={datas} />} />
            <Route path="/Contact" element={<Contact contents={datas} />} />
            <Route path="/Booking" element={<Booking contents={datas} />} />
            <Route path="/About" element={<About contents={datas} />} />
            <Route path="/Content/:slug" element={<Content contents={datas} />} />


            <Route path="/Glamping" element={<Glamping contents={datas} />} />
            <Route path="/OnGridCamping" element={<OnGridCamping contents={datas} />} />
            <Route path="/OffGridCamping" element={<OffGridCamping contents={datas} />} />
            <Route path="/CarCamping" element={<CarCamping contents={datas} />} />
            <Route path="/Eden" element={<Eden contents={datas} />} />
            <Route path="/Stellar" element={<Stellar contents={datas} />} />
            <Route path="/Coasta" element={<Coasta contents={datas} />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy contents={datas} />} />
            <Route path="/RefundAndCancellation" element={<RefundAndCancellation contents={datas} />} />
          </Routes>
          <MainFooter contents={datas} />
        </ScrollToTop>
        <PDPAPopup showPopup={datas.popup_pdpa} />
      </BrowserRouter>
    </>
  )
}

export default App