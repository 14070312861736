// version 0.3

import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router";
import { useApi, urlApi, baseName, apiEndpointMapping } from '../Constants/Global';

const ObjRouteManager = (props) => {
  // State to store the fetched data
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState(null);

  // Get the current route path
  const fullPath = window.location.pathname;
  const currentPath = fullPath.replace(baseName, '');
  const pathSplit = currentPath.split('/');
  const pathLength = pathSplit.length;
  const path = pathLength === 1 ? '/' : '/' + pathSplit[1];
  const param = pathLength > 2 ? pathSplit[2] : '';
  const apiMapped = apiEndpointMapping[`${path}`];
  const apiEndpoint = (pathLength > 3 ? '../' : '../') + urlApi + '/?module=' + apiMapped.module + "&call=" + apiMapped.call + (param !== '' ? '&param=' + param : '');
  const pageObj = {
    results: {
      page_name: apiMapped.name, 
      page_path: path, 
      navColor: apiMapped.navColor
    }
  }

  console.log("ObjRouteManager", apiEndpoint);

  useEffect(() => {
    // Use the route path to determine the API endpoint
    // const apiEndpoint = apiEndpointMapping[path];

    // Fetch data from your API here
    // You can use the Fetch API or Axios for this
    if (useApi && (prevLocation === null || prevLocation.pathname !== location.pathname)) {
      console.log("fetch new content !");
      fetch(apiEndpoint)
        .then((response) => response.json())
        .then((result) => {
          console.log('result', result);
          result.results.page_name = apiMapped.name;
          props.changeHandler(result.results);
        })
        .catch((error) => console.error('Error fetching data:', error));
    } else if(prevLocation === null || prevLocation.pathname !== location.pathname) {
      props.changeHandler(pageObj.results);
    }
    setPrevLocation(location);
  }, [location, prevLocation, apiEndpoint, props, pageObj]);

  return <>{props.children}</>
};

export default ObjRouteManager;