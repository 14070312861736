import React from 'react';
import { Col } from 'react-bootstrap';

const ContentImage = ({src, size, width, height}) => {
  return (
    <>
      <Col xs={12} sm={12} md={size} lg={size} xl={size} xxl={size} className="d-flex align-content-center flex-wrap">
        {src !== '' ? (
          <img src={src} style={{width: width, height: height}} alt='Content' />
        ) : ('')}
      </Col>
    </>
  )
}

ContentImage.defaultProps = {
  src: '', 
  size: 12, 
  width: '100%', 
  height: 'auto'
}

export default ContentImage