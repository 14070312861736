import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SectionSpace from '../Components/SectionSpace';

const RefundAndCancellation = () => {
  return (
    <>
      <SectionSpace height={'90px'} />
      <section className='refund-and-cancallation'>
        <Container fluid className='px-5 px-xl-0'>
          <Row>
            <Col md={{ span: 4, offset: 4 }}>
              <div className='fs-custom-3 fw-bold mb-3 tx-color-primary tx-spacing-10'>Refund and Cancellation Policy</div>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 4, offset: 4 }}>
              <p>Bookings can be <b>changed</b> free of charge until 7 days before arrival. After that the booking can be changed until 5 days before arrival for the same or a higher price than the original booking.</p>
              <p>Bookings can be <b>cancelled</b> free of charge until 7 days before arrival. If the booking is cancelled within 7 days of arrival 50% of the total price will be charged. No shows will be fully charged.</p>
              <p>The modification/cancellation request for bookings made online will be processed automatically through the Internet Booking Engine. Any refund due shall be done through the system in accordance with the applicable modification/cancellation rules. In case of rebooking, a rate difference may be payable for the change as per the rate applicable for the rebooked date/ room type and subject to availability.</p>
              <p>We can only process bookings that have been made with us directly. Booking made through a travel agent or an online travel agency can be processed by the issuing travel agent only.</p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default RefundAndCancellation