import React from 'react'

function SectionHeader({topic, detail, textAlign, textColor, bg}) {
  return (
    <>
      <section className="ratio-header position-relative">
        <div className={`position-absolute translate-middle ${textAlign} ${textColor}`}>
          <div className="fs-1 text-uppercase tx-spacing-30">{topic}</div>
          <div className="fs-3 text-capitalize">{detail}</div>
        </div>
      </section>
    </>
  )
}

SectionHeader.defaultProps = {
  topic: '', 
  detail: '', 
  textAlign: 'top-50 start-50 text-center', 
  textColor: 'text-white', 
  bg: 'radial-gradient(circle at 0% 0%, #1B2252, #00423E 80%)'
}

export default SectionHeader