import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { IoChevronDown } from 'react-icons/io5';

const SectionCarousel = ({ contents, mockup, showNav, showIndicator, showCaretDown, bgClass, mainClass, moreClass, indicatorClass }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <section id="hero" className={`position-relative custom-carousel ${mainClass}`}>
        <Carousel interval={2000} controls={showNav} indicators={showIndicator} activeIndex={index} onSelect={handleSelect} className={`${(indicatorClass !== undefined ? indicatorClass : '')}`}>
          {contents.map((item, index) => (
            item.type === 'video' ? (
              <Carousel.Item key={index}>
                <video
                  width="100%"
                  height="auto"
                  autoPlay
                  muted
                  playsInline
                  loop
                  controls={false}
                >
                  <source src={item.row_value} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div className="position-absolute w-100 h-100 top-0 left-0">
                  <Carousel.Caption className="bottom-0 pb-0 pb-md-5 mb-0 mb-md-5 start-50 translate-middle w-100 px-4 px-md-5 text-end font-header text-uppercase tx-spacing-10 fs-caption-1" dangerouslySetInnerHTML={{ __html: item.detail }}>
                  </Carousel.Caption>
                </div>
              </Carousel.Item>
            ) : (
              <Carousel.Item key={index} className={`ratio-16x9 bg-img ${bgClass !== undefined ? bgClass : ''} ${moreClass !== undefined ? moreClass : ''}`} style={{ backgroundImage: 'url(' + item.row_value + ')' }}>
                <div className="position-absolute w-100 h-100 top-0 left-0">
                  <Carousel.Caption className="bottom-0 pb-0 pb-md-5 mb-0 mb-md-5 start-50 translate-middle w-100 px-4 px-md-5 text-end font-header text-uppercase tx-spacing-10 fs-caption-1" dangerouslySetInnerHTML={{ __html: item.detail }}>
                  </Carousel.Caption>
                </div>
              </Carousel.Item>
            )))}
        </Carousel>
        {showCaretDown ? (
          <div className="position-absolute w-100 text-center bottom-0 start-0 transform-middle pb-1 pb-md-3 tx-color-secondary">
            <IoChevronDown size={36} />
          </div>
        ) : ''}
      </section>
    </>
  )
}

SectionCarousel.defaultProps = {
  contents: []
}

export default SectionCarousel