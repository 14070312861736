import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { urlApi } from '../Constants/Global';
import SectionCarousel from '../Components/SectionCarousel';
import Photo1 from '../Images/final/4-offgrid/head.png';
import { Container, Row, Col } from 'react-bootstrap';

import Pic1 from '../Images/final-final/accom-offgrid/01.png';
import Pic2 from '../Images/final-final/accom-offgrid/02.png';
import Pic3 from '../Images/final-final/accom-offgrid/03.png';
import Pic4 from '../Images/final-final/accom-offgrid/04.png';
import Pic5 from '../Images/final-final/accom-offgrid/05.png';
import Pic6 from '../Images/final-final/accom-offgrid/06.png';
import Pic7 from '../Images/final-final/accom-offgrid/07.png';

import ZoneCamping1 from '../Images/final/4-offgrid/camp/01.png';
import ZoneCamping2 from '../Images/final/4-offgrid/camp/02.png';
import ZoneCamping3 from '../Images/final/4-offgrid/camp/03.png';
import SectionAccomodation from '../Components/SectionAccomodation';

const OffGridCamping = ({ contents }) => {
  console.log(contents)
  const storage = urlApi + '/storage/article/';

  const mockup = {
    slide: [
      { row_value: Photo1, detail: <></> }
    ],
    slide_content: [
      { row_value: Pic1 },
      { row_value: Pic2 },
      { row_value: Pic3 },
      { row_value: Pic4 },
      { row_value: Pic5 },
      { row_value: Pic6 },
      { row_value: Pic7 },
    ],
    slide_zone_camping: [
      { row_value: ZoneCamping1 },
      { row_value: ZoneCamping2 },
      { row_value: ZoneCamping3 },
    ]
  }

  const setDynamicHeader = () => {
    const windowWidth = window.innerWidth;
    const headerElement = document.querySelector(".header-lg");
    const topContainer = document.querySelector(".top-layer");

    // Check if the header element and topContainer exist
    if (headerElement && topContainer) {
      const headerHeight = parseInt(getComputedStyle(headerElement).height);

      // Assuming windowWidth is defined somewhere in your code
      if (windowWidth <= 576) {
        if (headerHeight > 35) {
          topContainer.style.marginTop = '-80px';
        } else {
          topContainer.style.marginTop = '-50px';
        }
      }
    }
  };

  const setDynamicHeight = () => {
    const windowWidth = window.innerWidth;
    const container = document.querySelectorAll('.layer-container');

    container.forEach(element => {
      const children = element ? element.children : [];

      let maxHeight = 0;

      const updateMaxHeight = () => {
        for (let i = 0; i < children.length; i++) {
          const childHeight = children[i].offsetTop + children[i].offsetHeight;
          if (childHeight > maxHeight) {
            maxHeight = childHeight;
          }
        }

        console.log(maxHeight);

        if (windowWidth <= 1199) {
          // in case of mobile size just disable it.
          element.style.height = 'unset';
        } else {
          element.style.height = maxHeight + 'px';
        }
      };

      // Check if all images are loaded before calculating maxHeight
      const images = element.querySelectorAll('img');
      const imageCount = images.length;
      let loadedImages = 0;

      const imageLoadedHandler = () => {
        loadedImages++;
        if (loadedImages === imageCount) {
          updateMaxHeight();
        }
      };

      images.forEach(image => {
        if (image.complete) {
          // Image is already loaded
          imageLoadedHandler();
        } else {
          // Add a load event listener to each image
          image.addEventListener('load', imageLoadedHandler);
        }
      });
    });
  };

  useEffect(() => {
    setDynamicHeight();
    setDynamicHeader();

    // Event listener for window resize
    const handleResize = () => {
      setDynamicHeight();
      // You may also want to call setDynamicHeader() here if needed
    };

    // Attach event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <SectionCarousel showNav={false} showIndicator={false} showCaretDown={false} mockup={mockup.slide} />
      <section className='offgrid custom-header position-relative'>
        <Container fluid>
          <div className='layer-container position-xl-relative'>
            <Row className='layer-item'>
              <Col className='text-center'>
                <div className='position-relative header-lg font-header text-uppercase tx-color-secondary'>Off-Grid Camping</div>
                <div className='position-relative subheader-lg tx-spacing-15 text-uppercase'>New Challenges</div>
              </Col>
            </Row>
          </div>

          <div className='layer-container position-static position-xl-relative top-layer'>
            <Row className='layer-item position-static position-xl-absolute' style={{ zIndex: 2 }}>
              <Col className='px-0' xl={{ span: 7, offset: 5 }}>
                <div className='layer-content-text top-header'>
                  <p className='fs-custom-5 lh-12 text-white'>Step into the realm of adventure with our off-grid areas, an invitation to push your limits in the embrace of the great outdoors. These open plots, nestled within the arms of nature, provide the perfect canvas for you to challenge and enhance your outdoor skills. Embrace the raw beauty of nature as your backdrop, setting the stage for a complete immersion into the wilderness.</p>
                  <p className='fs-custom-5 lh-12 text-white'>Amidst this landscape, you'll find modern, pristine bathrooms offering separate wet and dry areas, ensuring your comfort after a day of exploration. It's time to embark on a journey that tests your mettle, surrounded by the serenity and grandeur of the natural world.</p>
                  <Link to="/Booking">
                    <div className='d-inline-block d-xl-none btn-booknow text-center tx-color-primary bg-color-secondary p-2 mt-3 mt-xl-5 mb-3 mb-xl-0 fw-bold text-uppercase'>Book Now</div>
                  </Link>
                  <Link to="/Booking">
                    <div className='position-absolute d-none d-xl-inline-block btn-booknow text-center tx-color-primary bg-color-secondary p-2 fw-bold text-uppercase' style={{ bottom: '-40px', right: '5rem' }}>Book Now</div>
                  </Link>
                </div>
              </Col>
            </Row>
            <Row className='layer-item position-static position-xl-relative' style={{ zIndex: 4, pointerEvents: 'none' }}>
              <Col className='px-5' xl={{ span: 7, offset: 0 }} style={{ pointerEvents: 'all' }}>
                <div className='layer-content-slide ps-xl-5'>
                  <SectionCarousel showNav={false} showIndicator={true} showCaretDown={false} mockup={mockup.slide_content} bgClass={'bg-transparent'} moreClass={'custom-ratio-slide'} indicatorClass={'offgrid-indicator-primary'} />
                </div>
              </Col>
            </Row>
          </div>

          <Row className='bg-color-primary content-2 px-0 px-xl-5'>
            <Col className="px-5 px-xl-0 pb-5 mb-5 mb-xl-0 pe-4 pe-xl-0">
              <Row className='w-100'>
                <Col xl={5} className='pe-5 pe-xl-5 ps-5 pt-5 pt-xl-0 ps-xl-5'>
                  <div className='mt-3 mt-xl-5 fs-custom-2 lh-12 fw-bold tx-color-secondary text-uppercase tx-spacing-10 mb-3'>Campground Options</div>
                  <p className='fs-custom-5 lh-12 text-white'>We offer a variety of private camping areas with different spacings to allows you to find the perfect fit for your accommodation needs. Whether you are on a journey as a couple, a family, a group of friends, no matter if you are traveling with minimal equipment or a luxurious and spacious tent, we have the perfect spot for you.</p>
                </Col>
                <Col xl={7} className="text-end p-0 px-2 px-xl-0 ps-2 ps-xl-5 pe-2 pe-xl-0 slide-object-zone position-relative">
                  <div className='mb-5'>
                    <SectionCarousel showNav={false} showIndicator={true} showCaretDown={false} mockup={mockup.slide_zone_camping} bgClass={'bg-transparent'} moreClass={'custom-ratio-slide'} indicatorClass={'glamping-indicator-secondary'} />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col lg={{ span: 8, offset: 2 }} className="px-5 px-xl-0 pb-5 pt-5 mb-5 mt-5 mb-xl-0">
              <div className='embed-video-container mt-5'>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/hDbopH6wsng?si=9OOucaZ89tWebWkN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
            </Col>
          </Row>
        </Container>

      </section>

      <div className='pt-0 mt-0 pt-xl-0 mt-xl-5 px-5 px-xl-0'>
        <SectionAccomodation current={'OffGridCamping'} showHeader={true} headerText={'Explore More'} sizingXL={{ span: 8, offset: 2 }} />
      </div>
    </>
  )
}

OffGridCamping.defaultProps = {
  contents: {
    lang: 'th',
    content: {
      contents: []
    }
  },
  path: '',
  lang: 'th',
  slides: [],
  slide_events: []
}

export default OffGridCamping