import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SectionCarousel from './SectionCarousel';
import Bg from '../Images/mockup/background.png';
import Bg1 from '../Images/update/accomodations/01BG.png';
import Bg2 from '../Images/update/accomodations/02BG.png';
import Bg3 from '../Images/update/accomodations/03BG.png';
import Bg4 from '../Images/update/accomodations/04BG.png';
import Icon1 from '../Images/update/accomodations/01icon.png';
import Icon2 from '../Images/update/accomodations/02icon.png';
import Icon3 from '../Images/update/accomodations/03icon.png';
import Icon4 from '../Images/update/accomodations/04icon.png';
import AccomPhoto1 from '../Images/final-final/home-slide/01.png';
import AccomPhoto2 from '../Images/final-final/home-slide/02.png';
import AccomPhoto3 from '../Images/final-final/home-slide/03.png';
import AccomPhoto4 from '../Images/final-final/home-slide/04.png';

const SectionAccomodation = ({ current, contents, showHeader, showSlide, showParagraph, headerText, sizingXL, slides }) => {
  const mockup = {
    slide: [
      { row_value: AccomPhoto1, type: 'image' },
      { row_value: AccomPhoto2, type: 'image' },
      { row_value: AccomPhoto3, type: 'image' },
      { row_value: AccomPhoto4, type: 'image' },
    ]
  }

  return (
    <>
      {showHeader ? (
        // <Row className='bg-img pt-0 pt-md-5 position-relative' style={{ backgroundImage: `url(${Bg})`, backgroundColor: 'transparent', backgroundPosition: 'bottom center' }}>
        //   <div className="position-absolute top-0 w-100 p-0 bg-custom-height" style={{ height: '200px', background: 'linear-gradient(180deg, rgba(228,220,209,1) 0%, rgba(228,220,209,0) 100%)' }}></div>
        //   <Col className='pt-0 pt-md-5'>
        //     <div className="fs-custom-3 fw-bold pt-4 pt-md-5 text-uppercase tx-color-dark tx-spacing-10 mb-2 mb-md-4">Accommodations</div>
        //   </Col>
        // </Row>
        <Row className='pt-0 pt-md-0 position-relative'>
          <Col className='pt-0 pt-md-5'>
            <div className="fs-custom-3 fw-bold pt-4 pt-md-0 text-center text-uppercase tx-color-dark tx-spacing-10 mb-2 mb-md-4">
              {headerText ? headerText : 'Accommodations'}
            </div>
          </Col>
        </Row>
      ) : ''}
      {showParagraph ? (
        <Row>
          <Col className='p-0 mb-1 px-2 px-xl-5'>
            <p className='fs-custom-5 lh-12 fw-light mb-3 mb-md-5'>
              CAMP CAYLA provides you with a variety of accommodations <br />
              for your personal camping journey. Get your first impressions with <br />
              the security and luxury of our glamping camps. Make <br />
              new experiences with on-grid camping while still having the comfort of electricity or <br />
              accept a new challenge with off-grid camping. With car camping you can set off to new <br />
              horizons. Just explore our accommodation types and find your perfect fit or <br />
              take your adventure to the next level.
            </p>
          </Col>
        </Row>
      ) : ''}
      {showSlide ? (
        <Row>
          <Col xl={sizingXL} className='p-0 mb-1'>
            <SectionCarousel showNav={false} showIndicator={true} showCaretDown={false} contents={slides} bgClass={'bg-transparent'} moreClass={'custom-ratio-home-accom'} />
          </Col>
        </Row>
      ) : ''}
      <Row className='accom-list text-center'>
        <Col xl={sizingXL}>
          <Row>
            {contents.map((item, index) => (
              <Col key={index} className='p-0'>
                {item.target === '/Accommodation/' + current ?
                  <div className='position-relative accom-item' style={{ filter: 'grayscale(1)' }}>
                    <img src={item.img} alt='background' className='w-100 accom-bg' />
                    <div className='position-absolute top-0 start-0'>
                      <img src={item.icon} alt='icon' className='accom-icon' style={{ width: '85%', marginTop: '7.5%' }} />
                    </div>
                  </div>
                  :
                  <Link to={item.target}>
                    <div className='position-relative accom-item'>
                      <img src={item.img} alt='background' className='w-100 accom-bg' />
                      <div className='position-absolute top-0 start-0'>
                        <img src={item.icon} alt='icon' className='accom-icon' style={{ width: '85%', marginTop: '7.5%' }} />
                      </div>
                    </div>
                  </Link>
                }
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </>
  )
}

SectionAccomodation.defaultProps = {
  current: '',
  contents: [
    { img: Bg1, icon: Icon1, target: '/Accommodation/Glamping' },
    { img: Bg2, icon: Icon2, target: '/Accommodation/OnGridCamping' },
    { img: Bg3, icon: Icon3, target: '/Accommodation/OffGridCamping' },
    { img: Bg4, icon: Icon4, target: '/Accommodation/CarCamping' },
  ], 
  slides: []
}

export default SectionAccomodation