import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const SectionService = ({ contents }) => {
  return (
    <>
      <section id="service">
        <Container fluid>
          <Row>
            {contents.map((item, index) => (
              <Col key={index} className="p-0 position-relative text-center bg-img bg-color-primary p-5" style={{ backgroundImage: 'url(' + '' + ')' }}>
                <img src={item.row_value} alt="icon" className="m-5" />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  )
}

SectionService.defaultProps = {
  contents: []
}

export default SectionService