import React from 'react';
import SectionHeader from '../Components/SectionHeader';
import SectionNews from '../Components/SectionNews';

function News() {
  return (
    <>
      <SectionHeader topic="News & Content" textColor={'text-white'} textAlign={'top-50 start-50 text-center'} />
      <SectionNews />
    </>
  )
}

export default News