import React from 'react';
import { FaLine, FaYoutube, FaFacebookF } from 'react-icons/fa';

function ObjSocialList(props) {
  return (
    <ul className='p-0 m-0'>
      <li className={'d-inline-block ' + props.iconSpace}>
        <a href="google.com" target="_blank" rel='noreferrer'>
          <FaYoutube size={props.iconSize} color={props.iconColor} />
        </a>
      </li>
      <li className={'d-inline-block ' + props.iconSpace}>
        <a href="google.com" target="_blank" rel='noreferrer'>
          <FaLine size={props.iconSize} color={props.iconColor} />
        </a>
      </li>
      <li className={'d-inline-block ' + props.iconSpace}>
        <a href="google.com" target="_blank" rel='noreferrer'>
          <FaFacebookF size={props.iconSize} color={props.iconColor} />
        </a>
      </li>
    </ul>
  )
}

ObjSocialList.defaultProps = {
  iconSize: 26,
  iconColor: '#FFF',
  iconSpace: 'pe-4'
}

export default ObjSocialList