import React from 'react';
import { Col } from 'react-bootstrap';
import ContentHTML from './ContentHTML';

const ContentText = ({content, size}) => {
  return (
    <>
      <Col xs={12} sm={12} md={size} lg={size} xl={size} xxl={size}>
        <ContentHTML htmlContent={content} />
      </Col>
    </>
  )
}

ContentText.defaultProps = {
  content: '', 
  size: 12
}

export default ContentText