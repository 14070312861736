import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { urlApi } from '../Constants/Global';
import SectionCarousel from '../Components/SectionCarousel';
import Photo1 from '../Images/final/5-carcamping/head.png';
import { Container, Row, Col } from 'react-bootstrap';

import Pic1 from '../Images/final-final/accom-carcamping/01.png';
import Pic2 from '../Images/final-final/accom-carcamping/02.png';
import Pic3 from '../Images/final-final/accom-carcamping/03.png';
import Pic4 from '../Images/final-final/accom-carcamping/04.png';

import SectionAccomodation from '../Components/SectionAccomodation';

const CarCamping = ({ contents }) => {
  console.log(contents)
  const storage = urlApi + '/storage/article/';

  const mockup = {
    slide: [
      { row_value: Photo1, detail: <></> }
    ],
    slide_content: [
      { row_value: Pic1 },
      { row_value: Pic2 },
      { row_value: Pic3 },
      { row_value: Pic4 },
    ]
  }

  const setDynamicHeader = () => {
    const windowWidth = window.innerWidth;
    const headerElement = document.querySelector(".header-lg");
    const topContainer = document.querySelector(".top-layer");

    // Check if the header element and topContainer exist
    if (headerElement && topContainer) {
      const headerHeight = parseInt(getComputedStyle(headerElement).height);

      // Assuming windowWidth is defined somewhere in your code
      if (windowWidth <= 1199) {
        if (headerHeight > 35) {
          topContainer.style.marginTop = '-80px';
        } else {
          topContainer.style.marginTop = '-50px';
        }
      }
    }
  };

  const setDynamicHeight = () => {
    const windowWidth = window.innerWidth;
    const container = document.querySelectorAll('.layer-container');

    container.forEach(element => {
      const children = element ? element.children : [];

      let maxHeight = 0;

      const updateMaxHeight = () => {
        for (let i = 0; i < children.length; i++) {
          const childHeight = children[i].offsetTop + children[i].offsetHeight;
          if (childHeight > maxHeight) {
            maxHeight = childHeight;
          }
        }

        console.log(maxHeight);

        if (windowWidth <= 1199) {
          // in case of mobile size just disable it.
          element.style.height = 'unset';
        } else {
          element.style.height = maxHeight + 'px';
        }
      };

      // Check if all images are loaded before calculating maxHeight
      const images = element.querySelectorAll('img');
      const imageCount = images.length;
      let loadedImages = 0;

      const imageLoadedHandler = () => {
        loadedImages++;
        if (loadedImages === imageCount) {
          updateMaxHeight();
        }
      };

      images.forEach(image => {
        if (image.complete) {
          // Image is already loaded
          imageLoadedHandler();
        } else {
          // Add a load event listener to each image
          image.addEventListener('load', imageLoadedHandler);
        }
      });
    });
  };

  useEffect(() => {
    setDynamicHeight();
    setDynamicHeader();

    // Event listener for window resize
    const handleResize = () => {
      setDynamicHeight();
      // You may also want to call setDynamicHeader() here if needed
    };

    // Attach event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <SectionCarousel showNav={false} showIndicator={false} showCaretDown={false} mockup={mockup.slide} />
      <section className='carcamping custom-header position-relative'>
        <Container fluid>
          {/* <div className='layer-container position-xl-relative'>
            <Row className='layer-item'>
              <Col className='text-center text-xl-start'>
                <div className='position-relative header-lg font-header text-uppercase tx-color-secondary'>Car Camping</div>
                <div className='position-relative subheader-lg tx-spacing-15 text-uppercase'>New Horizons</div>
              </Col>
            </Row>
          </div> */}

          <div className='layer-container position-xl-relative'>
            <Row className='layer-item'>
              <Col className='text-center'>
                <div className='position-relative header-lg font-header text-uppercase tx-color-secondary'>Car Camping</div>
                <div className='position-relative subheader-lg tx-spacing-15 text-uppercase'>New Horizons</div>
              </Col>
            </Row>
          </div>

          <div className='layer-container position-static position-xl-relative top-layer'>
            <Row className='layer-item position-static position-xl-absolute' style={{ zIndex: 2 }}>
              <Col className='px-0' xl={{ span: 7 }}>
                <div className='layer-content-text top-header'>
                  <p className='fs-custom-5 lh-12 text-white'>For the car enthusiasts seeking a distinct experience, our car camping lots await. These dedicated plots offer more than just a place to park; they provide a vantage point that unveils the surrounding landscape in all its glory. Here, your car takes center stage, showcasing its capabilities while granting you front-row seats to the captivating view of Mother Nature.</p>
                  <p className='fs-custom-5 lh-12 text-white'>Amidst this automotive haven, pristine modern bathrooms await, complete with separate wet and dry areas for your utmost comfort. It's a rendezvous of passions - where the prowess of your vehicle harmonises with the breath-taking landscape, offering a symphony of adventure and natural beauty.</p>
                  <Link to="/Booking">
                    <div className='d-inline-block d-xl-none btn-booknow text-center tx-color-primary bg-color-secondary p-2 mt-3 mt-xl-5 mb-3 mb-xl-0 fw-bold text-uppercase'>Book Now</div>
                  </Link>
                  <Link to="/Booking">
                    <div className='position-absolute d-none d-xl-inline-block btn-booknow text-center tx-color-primary bg-color-secondary p-2 fw-bold text-uppercase' style={{ bottom: '-40px' }}>Book Now</div>
                  </Link>
                </div>
              </Col>
            </Row>
            <Row className='layer-item position-static position-xl-relative' style={{ zIndex: 4, pointerEvents: 'none' }}>
              <Col className='px-5' xl={{ span: 7, offset: 5 }} style={{ pointerEvents: 'all' }}>
                <div className='layer-content-slide pe-xl-5'>
                  <SectionCarousel showNav={false} showIndicator={true} showCaretDown={false} mockup={mockup.slide_content} bgClass={'bg-transparent'} moreClass={'custom-ratio-slide'} indicatorClass={'carcamping-indicator-primary'} />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      <div className='pt-0 mt-5 pt-xl-5 mt-xl-5 px-5 px-xl-0'>
        <SectionAccomodation current={'CarCamping'} showHeader={true} headerText={'Explore More'} sizingXL={{ span: 8, offset: 2 }} />
      </div>
    </>
  )
}

CarCamping.defaultProps = {
  contents: {
    lang: 'th',
    content: {
      contents: []
    }
  },
  path: '',
  lang: 'th',
  slides: [],
  slide_events: []
}

export default CarCamping