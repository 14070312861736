import React from 'react';
import SectionSpace from '../Components/SectionSpace';
import SectionContact from '../Components/SectionContact';

const Contact = ({contents}) => {
  return (
    <>
      <SectionSpace height={'90px'} />
      <SectionContact contact={contents.contact} branch={contents.locations} />
    </>
  )
}

export default Contact