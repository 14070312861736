import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SectionSpace from '../Components/SectionSpace';

const Content = ({contents}) => {
  return (
    <>
      <SectionSpace height={'90px'} />
      <section className='privacy-policy' style={{ marginTop: '100px' }}>
        <Container fluid className='px-5 px-xl-0'>
          <Row>
            <Col md={{ span: 4, offset: 4 }}>
              <div className='fs-custom-3 fw-bold mb-3 tx-color-primary tx-spacing-10'>{contents?.content?.name}</div>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 4, offset: 4 }} dangerouslySetInnerHTML={{ __html: contents?.content?.content }}></Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Content