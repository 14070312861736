import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SectionHeader from '../Components/SectionHeader';
import { FaFacebook, FaYoutube } from 'react-icons/fa';

function ArticleView({ contents, trainer }) {
  return (
    <>
      <SectionHeader topic="About Us" textColor={'text-white'} textAlign={'top-50 start-50 text-center'} />
      <section>
        <Container fluid className='pt-5 pb-5'>
          <Row>
            <Col className='text-center text-white'>
              <p className='pt-4 fs-4'>Knowledge creates Creativity</p>
              <p className='mb-1 fs-5 fw-light'>เรามีความเชื่อมั่นว่าความคิดสร้างสรรค์ ล้วนมีพื้นฐานมาจากความรู้เริ่มต้นที่ถูกต้อง </p>
              <p className='mb-1 fs-5 fw-light'>ยิ่งเราเรียนรู้มากเท่าไหร่ เรายิ่งสามารถจินตนาการและ สร้างสรรค์ ได้มากขึ้นเท่านั้น </p>
              <p className='mb-1 fs-5 fw-light'>WOZ จึงมีเป้าหมายที่จะส่งมอบทั้งความรู้และประสบการณ์ แนวคิดและมุมมองใหม่ ๆ </p>
              <p className='mb-1 fs-5 fw-light'>เพื่อให้เป็นวัตถุดิบสำหรับการคิดอย่างสร้างสรรค์เพื่อนำไปสู่การพัฒนาและความสำเร็จ</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container className='pt-5 pb-5'>
          <Row className='pt-5 mb-5'>
            <Col className='text-center text-white fs-1'>
              ผู้สอนของเรา
            </Col>
          </Row>
          <Row className='pt-5'>
            {trainer.map((item, index) => (
              <Col xs={12} md={4} className='mb-5 px-5'>
                <div className='mb-5 text-center text-white'>
                  <div className='ratio-1x1 rounded-circle bg-img' style={{ backgroundImage: `url(${item.row_value})` }}></div>
                  <div className='fs-4 mt-3'>{item.name}</div>
                  <div className='fs-5 fw-light'>( {item.nickname} )</div>
                  <ul className='p-0 m-0'>
                    <li className='d-inline-block fs-4 px-1'>
                      <Link><FaFacebook color={'#FF5A3C'} /></Link>
                    </li>
                    <li className='d-inline-block fs-4 px-1'>
                      <Link><FaYoutube color={'#FF5A3C'} /></Link>
                    </li>
                  </ul>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  )
}

ArticleView.defaultProps = {
  contents: [],
  trainer: []
}


export default ArticleView