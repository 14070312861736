import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function SectionNews({ contents, isHome }) {
  const minPercentage = 80;
  const maxPercentage = 160;
  const [selectCategory, setSelectCategory] = useState('');

  const handleClick = (sel_item) => {
    console.log("handleClick", sel_item);
    setSelectCategory(sel_item);
  }

  return (
    <section className="pt-5 pb-5">
      <Container className='pt-5 pb-5'>
        {isHome ? (
          <>
            <Row className='mb-5'>
              <Col className='text-center fs-1'>
                <Link to='/News' className="text-white text-decoration-none">
                  News & Content
                </Link>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col className="text-center">
                <ul className="p-0 m-0">
                  <li className="d-inline-block px-2">
                    <Button variant='outline-light' onClick={() => handleClick('')} className="rounded-pill fs-4 px-5">All</Button>
                  </li>
                  <li className="d-inline-block px-2">
                    <Button variant='outline-light' onClick={() => handleClick('news')} className="rounded-pill fs-4 px-5">News</Button>
                  </li>
                  <li className="d-inline-block px-2">
                    <Button variant='outline-light' onClick={() => handleClick('content')} className="rounded-pill fs-4 px-5">Content</Button>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} className="as-column-3">
                {selectCategory !== '' ?
                  contents.map((item, index) => (
                    index < 10 && selectCategory === item.row_key ?
                      <div className='position-relative mb-5' style={{ backgroundImage: item.row_value, paddingBottom: (Math.random() * (maxPercentage - minPercentage) + minPercentage) + '%' }} key={index}>
                        <Link to='/Event/Event-01'>
                          <div className='position-absolute bottom-0 text-white bg-dark w-100 p-3 px-4 fs-5'>
                            <span className='position-absolute start-0 ps-4 fs-6' style={{ marginTop: '-50px' }}>{item.event_date}</span>
                            <span className='position-absolute end-0 pe-4 fs-6' style={{ marginTop: '-50px' }}>{item.trainer}</span>
                            {item.name}
                          </div>
                        </Link>
                      </div>
                    : ''
                  )) : 
                  contents.map((item, index) => (
                    index < 10 ?
                      <div className='position-relative mb-5' style={{ backgroundImage: item.row_value, paddingBottom: (Math.random() * (maxPercentage - minPercentage) + minPercentage) + '%' }} key={index}>
                        <Link to='/Event/Event-01'>
                          <div className='position-absolute bottom-0 text-white bg-dark w-100 p-3 px-4 fs-5'>
                            <span className='position-absolute start-0 ps-4 fs-6' style={{ marginTop: '-50px' }}>{item.event_date}</span>
                            <span className='position-absolute end-0 pe-4 fs-6' style={{ marginTop: '-50px' }}>{item.trainer}</span>
                            {item.name}
                          </div>
                        </Link>
                      </div>
                    : ''
                  ))
                }
              </Col>
            </Row>
            <Row className='mt-5'>
              <Col className='text-center'>
                <Link to='/News'>
                  <Button variant='outline-light' className="rounded-pill fs-4 px-5">
                    More
                  </Button>
                </Link>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row className="mb-4">
              <Col className="text-center">
                <ul className="p-0 m-0">
                  <li className="d-inline-block px-2">
                    <Button variant='outline-light' onClick={() => handleClick('')} className="rounded-pill fs-4 px-5">All</Button>
                  </li>
                  <li className="d-inline-block px-2">
                    <Button variant='outline-light' onClick={() => handleClick('news')} className="rounded-pill fs-4 px-5">News</Button>
                  </li>
                  <li className="d-inline-block px-2">
                    <Button variant='outline-light' onClick={() => handleClick('content')} className="rounded-pill fs-4 px-5">Content</Button>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} className='as-column-3'>
                {selectCategory !== '' ?
                  contents.map((item, index) => (
                    item.row_key === selectCategory ?
                      <div className='position-relative mb-5' style={{ backgroundImage: item.row_value, paddingBottom: (Math.random() * (maxPercentage - minPercentage) + minPercentage) + '%' }} key={index}>
                        <Link to='/Event/Event-01'>
                          <div className='position-absolute bottom-0 text-white bg-dark w-100 p-3 px-4 fs-5'>
                            <span className='position-absolute start-0 ps-4 fs-6' style={{ marginTop: '-50px' }}>{item.event_date}</span>
                            <span className='position-absolute end-0 pe-4 fs-6' style={{ marginTop: '-50px' }}>{item.trainer}</span>
                            {item.name}
                          </div>
                        </Link>
                      </div>
                      : ''
                  )) :
                  contents.map((item, index) => (
                    <div className='position-relative mb-5' style={{ backgroundImage: item.row_value, paddingBottom: (Math.random() * (maxPercentage - minPercentage) + minPercentage) + '%' }} key={index}>
                      <Link to='/Event/Event-01'>
                        <div className='position-absolute bottom-0 text-white bg-dark w-100 p-3 px-4 fs-5'>
                          <span className='position-absolute start-0 ps-4 fs-6' style={{ marginTop: '-50px' }}>{item.event_date}</span>
                          <span className='position-absolute end-0 pe-4 fs-6' style={{ marginTop: '-50px' }}>{item.trainer}</span>
                          {item.name}
                        </div>
                      </Link>
                    </div>
                  ))
                }
              </Col>
            </Row>
          </>
        )}
      </Container>
    </section>
  )
}

SectionNews.defaultProps = {
  isHome: false,
  contents: [
    { id: 1, row_key: 'news', name: 'Wise&Wellness Corporate Training', trainer: '', event_date: '2022-06-27', row_value: 'radial-gradient(circle at 0% 0%, rgb(249, 89, 60), #00423E 80%)' },
    { id: 1, row_key: 'news', name: 'Color Grading with DaVinci Resolve Certification Class', trainer: '', event_date: '2022-07-30', row_value: 'radial-gradient(circle at 0% 0%, #1B2252, rgb(249, 89, 60) 80%)' },
    { id: 1, row_key: 'news', name: 'Blackmagic Design #PopUpWorkshops @Khon Kaen', trainer: '', event_date: '2022-09-24', row_value: 'radial-gradient(circle at 0% 0%, #1B2252, #00423E 80%)' },
    { id: 1, row_key: 'content', name: 'Workshop การถ่ายภาพยนตร์เพื่อการทำ Color grading @มหาวิทยาลัยศรีปทุม', trainer: '', event_date: '2022-10-06', row_value: 'radial-gradient(circle at 0% 0%, rgb(249, 89, 60), #00423E 80%)' },
    { id: 1, row_key: 'content', name: 'Angenieux Optimo Primes & IOP Roadshow : Thailand', trainer: '', event_date: '2022-10-11', row_value: 'radial-gradient(circle at 0% 0%, #1B2252, #00423E 80%)' },
    { id: 1, row_key: 'news', name: 'Blackmagic Design #PopUpWorkshops @Phuket', trainer: '', event_date: '2022-10-29', row_value: 'radial-gradient(circle at 0% 0%, #1B2252, rgb(249, 89, 60) 80%)' },
    { id: 1, row_key: 'content', name: 'Blackmagic Design #PopUpWorkshops @Chiangmai', trainer: '', event_date: '2022-11-12', row_value: 'radial-gradient(circle at 0% 0%, #1B2252, rgb(249, 89, 60) 80%)' },
    { id: 1, row_key: 'news', name: 'คอร์สตัดต่อสำหรับมือใหม่ สายคอนเทนต์ครีเอเตอร์', trainer: 'โดย จารย์กุ้ง', event_date: '2023-01-28', row_value: 'radial-gradient(circle at 0% 0%, #1B2252, rgb(249, 89, 60) 80%)' },
  ]
}

export default SectionNews