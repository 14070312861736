import React from 'react';
import { urlApi } from '../Constants/Global';
import SectionCarousel from '../Components/SectionCarousel';
import { Container, Row, Col } from 'react-bootstrap';
import Pic1 from '../Images/mockup/location/photo-stellar-1.png';

import Photo1 from '../Images/update2/stellar/HEAD-STELLAR.png';
import Map from '../Images/final-final/location-stellar/map.png';
import MapArea from '../Images/final-final/location-stellar/map-area.jpg';

import Album1 from '../Images/final-final/location-stellar/slide/01.png';
import Album2 from '../Images/final-final/location-stellar/slide/02.png';
import Album3 from '../Images/final-final/location-stellar/slide/03.png';
import Album4 from '../Images/final-final/location-stellar/slide/04.png';
import Album5 from '../Images/final-final/location-stellar/slide/05.png';
import Album6 from '../Images/final-final/location-stellar/slide/06.png';
import Album7 from '../Images/final-final/location-stellar/slide/07.png';
import Album8 from '../Images/final-final/location-stellar/slide/08.png';
import Album9 from '../Images/final-final/location-stellar/slide/09.png';
import Album10 from '../Images/final-final/location-stellar/slide/10.png';
import Album11 from '../Images/final-final/location-stellar/slide/11.png';
import Album12 from '../Images/final-final/location-stellar/slide/12.png';
import Album13 from '../Images/final-final/location-stellar/slide/13.png';

import Offer1 from '../Images/final-final/location-stellar/activity/01.png';
import Offer2 from '../Images/final-final/location-stellar/activity/02.png';
import Offer3 from '../Images/final-final/location-stellar/activity/03.png';
import Offer4 from '../Images/final-final/location-stellar/activity/04.png';
import Offer5 from '../Images/final-final/location-stellar/activity/05.png';
import Offer6 from '../Images/final-final/location-stellar/activity/06.png';
import Offer7 from '../Images/final-final/location-stellar/activity/07.png';

import Sign1 from '../Images/final/logo/stellar.png';
import SectionAccomodation from '../Components/SectionAccomodation';
import SectionLocationExplore from '../Components/SectionLocationExplore';

const Stellar = ({ contents }) => {
  console.log(contents)
  const storage = urlApi + '/storage/article/';

  const mockup = {
    slide: [
      { row_value: Photo1, detail: <></> }
    ],
    slideSecondary: [
      { row_value: Album1, detail: <></> },
      { row_value: Album2, detail: <></> },
      { row_value: Album3, detail: <></> },
      { row_value: Album4, detail: <></> },
      { row_value: Album5, detail: <></> },
      { row_value: Album6, detail: <></> },
      { row_value: Album7, detail: <></> },
      { row_value: Album8, detail: <></> },
      { row_value: Album9, detail: <></> },
      { row_value: Album10, detail: <></> },
      { row_value: Album11, detail: <></> },
      { row_value: Album12, detail: <></> },
      { row_value: Album13, detail: <></> },
    ],
    slideOffer: [
      { row_value: Offer1, detail: <></> },
      { row_value: Offer2, detail: <></> },
      { row_value: Offer3, detail: <></> },
      { row_value: Offer4, detail: <></> },
      { row_value: Offer5, detail: <></> },
      { row_value: Offer6, detail: <></> },
      { row_value: Offer7, detail: <></> },
    ]
  }

  return (
    <>
      <SectionCarousel showNav={false} showIndicator={false} showCaretDown={false} mockup={mockup.slide} />
      <section className='custom-header position-relative location-header'>
        <Container fluid className='content-top'>
          <Row>
            <Col className='text-center'>
              <img src={Sign1} className='location-sign' alt="sign" />
            </Col>
          </Row>
          <Row className='mb-4 mb-xl-5'>
            <Col className='text-center px-5'>
              <div className="fs-custom-1 fw-bold pt-5 mb-5 text-uppercase tx-color-dark tx-spacing-10 lh-1">Stellar - Chiang Mai</div>
              <p className='fs-custom-5 lg-12 mb-3 mb-xl-4'>Nestled within the enchanting Mae Wang area close to Chiang Mai, <br />
                CAMP CAYLA - STELLAR invites you to embark on a celestial journey full of unforgettable <br />
                impressions. Set against a backdrop of lush, emerald hills, this refuge offers a serene <br />
                escape from the bustle of everyday life. As night falls, be mesmerised by the breath-taking <br />
                view of the stars above, guiding you through the cosmos. Accessible only to the fortunate few, <br />
                STELLAR's exclusive location promises a haven of peace and solitude, where the symphony <br />
                of nature's tranquillity harmonises with the heartbeats of the earth.</p>
              <p className='fs-custom-5 lg-12'>With its proximity to ethnic hill tribes, you'll have the opportunity to immerse yourself <br />
                in a tapestry of cultures, enhancing your connection to both the land and its people. <br />
                Explore the beauty of peaceful nature in this unique sanctuary, <br />
                where stargazing and serenity converge to create an experience that's<br />
                truly out of this world.</p>
            </Col>
          </Row>
        </Container>
        <Container fluid className='px-5 px-xl-0'>
          <Row className='mb-1 mb-xl-2'>
            <Col xl={{ span: 8, offset: 2 }}>
              <SectionCarousel showNav={false} showIndicator={true} showCaretDown={false} mockup={mockup.slideSecondary} />
            </Col>
          </Row>
          <Row className='mb-1 mb-xl-2'>
            <Col xl={{ span: 8, offset: 2 }}>
              <img src={MapArea} className='w-100' alt="Pic" />
            </Col>
          </Row>
          <Row className='mb-1 mb-xl-2'>
            <Col xl={{ span: 8, offset: 2 }}>
              <a href='https://maps.app.goo.gl/683RcZRUcTpqCKjeA?g_st=il' target='_blank'>
                <img src={Map} className='w-100' alt="Pic" />
              </a>
            </Col>
          </Row>
          <Row className='mb-5'>
            <Col xl={{ span: 8, offset: 2 }}>
              <section className='mb-home-accom'>
                <Container fluid className='text-center'>
                  <SectionAccomodation showHeader={false} />
                </Container>
              </section>
            </Col>
          </Row>
        </Container>

        <Container fluid className='bg-color-primary pt-xl-5 pb-xl-5'>
          <Container>
            <Row>
              <Col xl={7} className='px-4 pe-xl-5 order-2 order-xl-1'>
                <div className='px-3 px-xl-0 pt-xl-5 pb-xl-5 location-offer-slide'>
                  <SectionCarousel showNav={false} showIndicator={true} showCaretDown={false} mockup={mockup.slideOffer} bgClass={'bg-transparent'} moreClass={'custom-ratio-slide'} indicatorClass={'location-indicator'} />
                </div>
              </Col>
              <Col xl={5} className='px-5 px-xl-0 order-1 order-xl-2'>
                <div className="fs-custom-3 fw-bold mb-4 mt-5 mt-xl-5 text-uppercase tx-color-secondary tx-spacing-10 lh-1">Activities<br /> and Offers</div>
                <p className='fs-custom-5 text-white lg-12' style={{ marginBottom: '30px' }}>With STELLAR's location on a mountain ridge in Northern Thailand you have a variety of natural sights and adventurous activities suiting young and old at your fingertips. Several rivers meander through the valley beneath, inviting you to take a dip in the refreshing cool water at the Mae Sapok or Mae Wang waterfalls. With an exciting bamboo rafting tour, you can discover the area with a different perspective from the water and if you are looking for an even more thrilling activity then you should visit the nearby Zip-line Park. Those looking for cultural experiences can visit one of the nearby Karen "Longneck" hill tribe villages or go on an adventurous trip to Wat Luang Khun Win, a 700 year old mystical temple in the jungle.Domesticated elephants have lost their purpose as work animals nowadays. The area hosts many elephant sanctuaries where these huge but humble animals can be experienced at close range in an ethical matter.</p>
              </Col>
            </Row>
          </Container>
        </Container>

        <Container fluid>
          <Row>
            <Col lg={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }} className="px-5 px-xl-0 pb-0 pt-5 mb-0 mt-5 mb-xl-0">
              <div className='embed-video-container mt-5'>
                <iframe width="956" height="538" src="https://www.youtube.com/embed/3sNAg3bc10o"
                  title="CAMP CAYLA - STELLAR" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
            </Col>
          </Row>
        </Container>

      </section>

      <SectionLocationExplore current={'/Stellar'} />
    </>
  )
}

Stellar.defaultProps = {
  contents: {
    lang: 'th',
    content: {
      contents: []
    }
  },
  path: '',
  lang: 'th',
  slides: [],
  slide_events: []
}

export default Stellar