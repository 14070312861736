import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { urlApi } from '../Constants/Global';
import ContentDisplay from '../Components/ContentDisplay';

import SectionCarousel from '../Components/SectionCarousel';
import SectionInfo from '../Components/SectionInfo';
import SectionService from '../Components/SectionService';
import SectionServiceFull from '../Components/SectionServiceFull';
import SectionPartner from '../Components/SectionPartner';
import SectionContact from '../Components/SectionContact';
import SectionEvent from '../Components/SectionEvent';
import SectionNews from '../Components/SectionNews';

import Photo1 from '../Images/mockup/home/home.png';
import Video1 from '../Medias/slide-video.mp4';
import SectionAccomodation from '../Components/SectionAccomodation';
import SectionLocation from '../Components/SectionLocation';

const Home = ({ contents }) => {
  console.log("Home contents passed :", contents);

  const storage = urlApi + '/storage/media/';
  const srcVideo = storage + 'video.mp4';

  const slide = [];
  contents?.content?.accom_slide?.map((item) =>
    slide.push({ row_value: storage + item, detail: '' })
  );

  return (
    <>
      <SectionCarousel 
        showNav={false} 
        showIndicator={false} 
        showCaretDown={true} 
        contents={[
          {
            row_value: srcVideo, 
            type: 'video', 
            detail: contents?.content?.header_topic?.replace(/\n/g, "<br>")
          }
        ]} 
      />
      <SectionInfo topic={contents?.content?.welcome_topic} detail={contents?.content?.welcome_detail?.replace(/\n/g, "<br>")} />
      <Container>
        <Row className='pt-0 pt-md-0 position-relative'>
          <Col className='pt-0 pt-md-5'>
            <div className="fs-custom-3 text-center fw-bold pt-4 pt-md-0 text-uppercase tx-color-dark tx-spacing-10 mb-2 mb-md-4">
              {contents?.content?.accom_topic}
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="px-5 px-xl-0">
            <section className='mb-home-accom'>
              <Container className='text-center'>
                <SectionAccomodation showHeader={false} showSlide={true} slides={slide} showParagraph={true} sizingXL={{ span: 8, offset: 2 }} />
              </Container>
            </section>
          </Col>
        </Row>
      </Container>
      <SectionLocation 
        topic={contents?.content?.location_topic} 
        detail={contents?.content?.location_detail?.replace(/\n/g, "<br>")} 
        contents={contents?.locations} 
      />
    </>
  )
}

Home.defaultProps = {
  contents: {
    content: {
      header_media: ''
    }
  }
}

export default Home