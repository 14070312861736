import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { urlApi } from '../Constants/Global';
import { Container, Row, Col } from 'react-bootstrap';
import { RxDotFilled as Dot } from "react-icons/rx";
import { RxPlus, RxMinus } from "react-icons/rx";
import SectionCarousel from '../Components/SectionCarousel';
import SectionAccomodation from '../Components/SectionAccomodation';

import icon1 from '../Images/update2/accom-icon/01.png';
import icon2 from '../Images/update2/accom-icon/02.png';
import icon3 from '../Images/update2/accom-icon/03.png';
import icon4 from '../Images/update2/accom-icon/04.png';
import icon5 from '../Images/update2/accom-icon/05.png';
import icon6 from '../Images/update2/accom-icon/06.png';
import icon7 from '../Images/update2/accom-icon/07.png';
import icon8 from '../Images/update2/accom-icon/08.png';
import icon9 from '../Images/update2/accom-icon/09.png';
import icon10 from '../Images/update2/accom-icon/10.png';
import icon11 from '../Images/update2/accom-icon/11.png';
import icon12 from '../Images/update2/accom-icon/12.png';
import icon13 from '../Images/update2/accom-icon/13.png';

const Accommodation = ({ contents }) => {
  console.log(contents)
  const storage = '../' + urlApi + '/storage/media/';

  const [showDetail1, setShowDetail1] = useState(true);
  const [showDetail2, setShowDetail2] = useState(true);

  const toggleDetail1 = () => {
    setShowDetail1(!showDetail1);
  };

  const toggleDetail2 = () => {
    setShowDetail2(!showDetail2);
  };

  const setDynamicHeader = () => {
    const windowWidth = window.innerWidth;
    const headerElement = document.querySelector(".header-lg");
    const topContainer = document.querySelector(".top-layer");

    // Check if the header element and topContainer exist
    if (headerElement && topContainer) {
      const headerHeight = parseInt(getComputedStyle(headerElement).height);

      // Assuming windowWidth is defined somewhere in your code
      if (windowWidth <= 1199) {
        if (headerHeight > 35) {
          topContainer.style.marginTop = '-80px';
        } else {
          topContainer.style.marginTop = '-50px';
        }
      }
    }
  };

  const setDynamicHeight = () => {
    const windowWidth = window.innerWidth;
    const container = document.querySelectorAll('.layer-container');

    container.forEach(element => {
      const children = element ? element.children : [];

      let maxHeight = 0;

      const updateMaxHeight = () => {
        for (let i = 0; i < children.length; i++) {
          const childHeight = children[i].offsetTop + children[i].offsetHeight;
          if (childHeight > maxHeight) {
            maxHeight = childHeight;
          }
        }

        console.log(maxHeight);

        if (windowWidth <= 1199) {
          // in case of mobile size just disable it.
          element.style.height = 'unset';
        } else {
          element.style.height = maxHeight + 'px';
        }
      };

      // Check if all images are loaded before calculating maxHeight
      const images = element.querySelectorAll('img');
      const imageCount = images.length;
      let loadedImages = 0;

      const imageLoadedHandler = () => {
        loadedImages++;
        if (loadedImages === imageCount) {
          updateMaxHeight();
        }
      };

      images.forEach(image => {
        if (image.complete) {
          // Image is already loaded
          imageLoadedHandler();
        } else {
          // Add a load event listener to each image
          image.addEventListener('load', imageLoadedHandler);
        }
      });
    });
  };

  useEffect(() => {
    setDynamicHeight();
    setDynamicHeader();

    // Event listener for window resize
    const handleResize = () => {
      setDynamicHeight();
      // You may also want to call setDynamicHeader() here if needed
    };

    // Attach event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const iconList = [
    { key: 'bed-queensize', icon: icon1 },
    { key: 'kettle', icon: icon8 },
    { key: 'smart-tv', icon: icon2 },
    { key: 'hair-dryer', icon: icon9 },
    { key: 'wifi', icon: icon3 },
    { key: 'umbrella', icon: icon10 },
    { key: 'coffee-drip', icon: icon4 },
    { key: 'glass', icon: icon11 },
    { key: 'deposit-box', icon: icon5 },
    { key: 'bluetooth-speaker', icon: icon12 },
    { key: 'mini-bar', icon: icon6 },
    { key: 'optional-bbq', icon: icon13 },
    { key: 'refrigerator', icon: icon7 },
  ]

  const header = [
    { row_value: storage + contents?.content?.header_img, detail: '' }
  ]

  const slide1 = [];
  contents?.content?.layout1_image?.map((item) =>
    slide1.push({ row_value: storage + item, detail: '' })
  );

  const slide2 = [];
  contents?.content?.layout2_image?.map((item) =>
    slide2.push({ row_value: storage + item, detail: '' })
  );

  function findObjectByKey(key, jsonArray) {
    for (var i = 0; i < jsonArray.length; i++) {
      if (jsonArray[i].key === key) {
        return jsonArray[i];
      }
    }
    return null; // Return null if the object is not found
  }

  return (
    <>
      <SectionCarousel showNav={false} showIndicator={false} showCaretDown={false} contents={header} />
      <section className='glamping custom-header position-relative'>
        <Container fluid>
          <div className='layer-container position-xl-relative'>
            <Row className='layer-item'>
              <Col className='text-center'>
                <div className='position-relative header-lg font-header text-uppercase tx-color-secondary'>{contents?.content?.name}</div>
                <div className='position-relative subheader-lg tx-spacing-15 text-uppercase'>{contents?.content?.detail}</div>
              </Col>
            </Row>
          </div>

          {contents?.content?.layout1_display === '1' ?
            <>
              {contents?.content?.layout1_image_location === 'right' ?
                <div className='layer-container position-static position-xl-relative top-layer'>
                  <Row className='layer-item position-static position-xl-absolute' style={{ zIndex: 2 }}>
                    <Col className='px-0' xl={{ span: 7 }}>
                      <div className={'layer-content-text on-left top-header ' + (contents?.content?.layout1_booknow === '1' ? 'with-booking' : '')}>
                        {contents?.content?.layout1_topic !== '' ?
                          <div className='fs-custom-2 fw-bold tx-color-secondary text-uppercase tx-spacing-10 mb-3'>
                            {contents?.content?.layout1_topic}
                          </div>
                          : ''}
                        <p className='fs-custom-5 lh-12 text-white' dangerouslySetInnerHTML={{ __html: contents?.content?.layout1_detail }}></p>
                        {contents?.content?.layout1_booknow === '1' ?
                          <>
                            <Link to="/Booking">
                              <div className='d-inline-block d-xl-none btn-booknow text-center tx-color-primary bg-color-secondary p-2 mt-3 mt-xl-5 mb-3 mb-xl-0 fw-bold text-uppercase'>Book Now</div>
                            </Link>
                            <Link to="/Booking">
                              <div className='position-absolute d-none d-xl-inline-block btn-booknow text-center tx-color-primary bg-color-secondary p-2 fw-bold text-uppercase' style={{ bottom: '-40px' }}>Book Now</div>
                            </Link>
                          </>
                          : ''}
                      </div>
                    </Col>
                  </Row>
                  <Row className='layer-item position-static position-xl-relative' style={{ zIndex: 4, pointerEvents: 'none' }}>
                    <Col className='px-5' xl={{ span: 7, offset: 5 }} style={{ pointerEvents: 'all' }}>
                      <div className='layer-content-slide pe-xl-5'>
                        <SectionCarousel
                          showNav={false}
                          showIndicator={true}
                          showCaretDown={false}
                          contents={slide1}
                          bgClass={'bg-transparent'}
                          mainClass={'right-top-round'}
                          moreClass={'custom-ratio-slide'}
                          indicatorClass={'ongrid-indicator-primary'}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                : // Display Image on Left side
                <div className={'layer-container position-static position-xl-relative top-layer'}>
                  <Row className='layer-item position-static position-xl-absolute' style={{ zIndex: 2 }}>
                    <Col className='px-0' xl={{ span: 7, offset: 5 }}>
                      <div className={'layer-content-text top-header ' + (contents?.content?.layout1_booknow === '1' ? 'with-booking' : '')}>
                        {contents?.content?.layout1_topic !== '' ?
                          <div className='fs-custom-2 fw-bold tx-color-secondary text-uppercase tx-spacing-10 mb-3'>
                            {contents?.content?.layout1_topic}
                          </div>
                          : ''}
                        <p className='fs-custom-5 lh-12 text-white' dangerouslySetInnerHTML={{ __html: contents?.content?.layout1_detail }}></p>
                        {contents?.content?.layout1_booknow === '1' ?
                          <>
                            <Link to="/Booking">
                              <div className='d-inline-block d-xl-none btn-booknow text-center tx-color-primary bg-color-secondary p-2 mt-3 mt-xl-5 mb-3 mb-xl-0 fw-bold text-uppercase'>Book Now</div>
                            </Link>
                            <Link to="/Booking">
                              <div className='position-absolute d-none d-xl-inline-block btn-booknow text-center tx-color-primary bg-color-secondary p-2 fw-bold text-uppercase' style={{ bottom: '-40px', right: '5rem' }}>Book Now</div>
                            </Link>
                          </>
                          : ''}
                      </div>
                    </Col>
                  </Row>
                  <Row className='layer-item position-static position-xl-relative' style={{ zIndex: 4 }}>
                    <Col className='px-5' xl={7}>
                      <div className='layer-content-slide ps-xl-5'>
                        <SectionCarousel
                          showNav={false}
                          showIndicator={true}
                          showCaretDown={false}
                          contents={slide1}
                          bgClass={'bg-transparent'}
                          mainClass={'left-top-round'}
                          moreClass={'custom-ratio-slide'}
                          indicatorClass={'glamping-indicator-primary'}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              }

              {contents?.content?.layout1_detail_more_display === '1' ?
                <Container className='mt-xl-5 mb-xl-0 pt-xl-5 pb-xl-0'>
                  <Row className='pt-5'>
                    <Col className='px-5 px-xl-0 fs-custom-5'>
                      <p className='fw-bold'>{contents?.content?.layout1_detail_more}</p>
                      <b>Detail</b>
                      <ul className='facility-list'>
                        {contents?.content?.layout1_bullet.map((item, index) => (
                          item !== '' ?
                            <li className='d-flex d-xl-inline-block' key={index}>
                              <Dot className='me-3' size={20} style={{ flex: 'none' }} />{item}
                            </li>
                            : ''
                        ))}
                      </ul>
                      <h6 className='fw-bold'>
                        Additional details
                        {/* <span className='float-end px-2' onClick={toggleDetail1}>
                          {showDetail1 ? (<RxMinus />) : (<RxPlus />)}
                        </span> */}
                      </h6>
                      {showDetail1 && (
                        <ul className='facility-list'>
                          {contents?.content?.layout1_facility.map((item, index) => (
                            findObjectByKey(item.key, contents?.content?.layout1_facility).display ?
                              <li className='d-flex d-xl-inline-block' key={index}>
                                <img src={findObjectByKey(item.key, iconList).icon} className='me-3' />
                                {findObjectByKey(item.key, contents?.content?.layout1_facility).name}
                              </li>
                              : ''
                          ))}
                        </ul>
                      )}
                    </Col>
                  </Row>
                </Container>
                : ''}
            </>
            : ''}

          {contents?.content?.layout2_display === '1' ?
            <>
              {contents?.content?.layout2_image_location === 'right' ?
                <Container fluid className='mt-xl-5 mb-xl-0 pt-xl-0 pb-xl-0 px-0 px-xl-0'>
                  <Row className={'bg-color-primary content-2 px-0 px-xl-5 ' + (contents?.content?.layout1_booknow === '1' ? 'with-booking' : '')}>
                    <Col className="px-5 px-xl-0 pb-5">
                      <Row className='pb-0 pb-xl-5'>
                        <Col xl={5} className='pe-2 pe-xl-5 ps-2 ps-xl-5 order-1 order-xl-1'>
                          {contents?.content?.layout2_topic !== '' ?
                            <div className='mt-3 mt-xl-5 fs-custom-2 fw-bold tx-color-secondary text-uppercase tx-spacing-10 mb-3'>
                              {contents?.content?.layout2_topic}
                            </div>
                            : ''}
                          <p className='fs-custom-5 lh-12 text-white' dangerouslySetInnerHTML={{ __html: contents?.content?.layout2_detail }}></p>

                          {contents?.content?.layout2_booknow === '1' ?
                            <Link to='/Booking'>
                              <div className='d-inline-block d-xl-none btn-booknow text-center tx-color-primary bg-color-secondary mt-3 p-2 px-5 fw-bold btn-booknow text-uppercase'>
                                Book Now
                              </div>
                            </Link>
                            : ''}

                        </Col>
                        <Col xl={7} className="text-end p-0 px-0 pb-5 px-xl-0 pe-xl-5 order-2 order-xl-2 slide-object-zone position-relative">
                          <div className='mb-5'>
                            <SectionCarousel
                              showNav={false}
                              showIndicator={true}
                              showCaretDown={false}
                              contents={slide2}
                              bgClass={'bg-transparent'}
                              mainClass={'right-top-round'}
                              moreClass={'custom-ratio-slide'}
                              indicatorClass={'glamping-indicator-tertiary'}
                            />
                          </div>

                          {contents?.content?.layout2_booknow === '1' ?
                            <Link to='/Booking'>
                              <div className='d-none d-xl-inline-block btn-booknow text-center tx-color-primary bg-color-secondary mt-5 mb-5 p-2 fw-bold btn-booknow text-uppercase'>Book Now</div>
                            </Link>
                            : ''}

                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
                : // Display Image on Left side
                <Container fluid className='mt-xl-5 mb-xl-0 pt-xl-0 pb-xl-0 px-0 px-xl-0'>
                  <Row className={'bg-color-primary content-2 px-0 px-xl-5 ' + (contents?.content?.layout1_booknow === '1' ? 'with-booking' : '')}>
                    <Col className="px-5 px-xl-0 pb-5 mb-5 mb-xl-0 pe-4 pe-xl-0">
                      <Row className='w-100'>
                        <Col xl={5} className='pe-5 pe-xl-5 ps-5 ps-xl-5 pt-5 pt-xl-0 order-xl-2'>
                          {contents?.content?.layout2_topic !== '' ?
                            <div className='mt-3 mt-xl-5 fs-custom-2 lh-12 fw-bold tx-color-secondary text-uppercase tx-spacing-10 mb-3'>{contents?.content?.layout2_topic}
                            </div>
                            : ''}
                          <p className='fs-custom-5 lh-12 text-white' dangerouslySetInnerHTML={{ __html: contents?.content?.layout2_detail }}></p>
                          {contents?.content?.layout2_booknow === '1' ?
                            <Link to='/Booking'>
                              <div className='d-inline-block d-xl-none btn-booknow text-center tx-color-primary bg-color-secondary mt-3 p-2 px-5 fw-bold btn-booknow text-uppercase'>
                                Book Now
                              </div>
                            </Link>
                            : ''}
                        </Col>
                        <Col xl={7} className="text-end p-0 px-0 px-xl-0 ps-0 ps-xl-5 pe-0 pe-xl-5 order-xl-1 slide-object-zone position-relative">
                          <div className='mb-5'>
                            <SectionCarousel
                              showNav={false}
                              showIndicator={true}
                              showCaretDown={false}
                              contents={slide2}
                              bgClass={'bg-transparent'}
                              mainClass={'left-top-round'}
                              moreClass={'custom-ratio-slide'}
                              indicatorClass={'location-indicator'}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              }

              {contents?.content?.layout2_detail_more_display === '1' ?
                <Container className='mt-xl-5 mb-xl-0 pt-xl-0 pb-xl-0 px-0 px-xl-5'>
                  <Row className='pt-0 pt-xl-5 mt-5 mt-xl-0'>
                    <Col className='px-5 px-xl-0 pt-5 pt-xl-0 fs-custom-5 mt-md-5 mt-5'>
                      <p className='fw-bold'>{contents?.content?.layout2_detail_more}</p>
                      <b>Detail</b>
                      <ul className='facility-list'>
                        {contents?.content?.layout2_bullet.map((item, index) => (
                          item !== '' ?
                            <li className='d-flex d-xl-inline-block' key={index}>
                              <Dot className='me-3' size={20} style={{ flex: 'none' }} />{item}
                            </li>
                            : ''
                        ))}
                      </ul>
                      <h6 className='fw-bold'>
                        Additional details
                        {/* <span className='float-end px-2' onClick={toggleDetail2}>
                          {showDetail2 ? (<RxMinus />) : (<RxPlus />)}
                        </span> */}
                      </h6>
                      {showDetail2 && (
                        <ul className='facility-list'>
                          {contents?.content?.layout2_facility.map((item, index) => (
                            findObjectByKey(item.key, contents?.content?.layout2_facility).display ?
                              <li className='d-flex d-xl-inline-block' key={index}>
                                <img src={findObjectByKey(item.key, iconList).icon} className='me-3' />
                                {findObjectByKey(item.key, contents?.content?.layout2_facility).name}
                              </li>
                              : ''
                          ))}
                        </ul>
                      )}
                    </Col>
                  </Row>
                </Container>
                : ''}
            </>
            : ''}
        </Container>

        {contents?.content?.row_embed !== null && contents?.content?.row_embed !== '' ?
          <Container fluid className='px-0 mt-5 mt-xl-0'>
            <Row>
              <Col lg={{ span: 12, offset: 0 }} xl={{ span: 8, offset: 2 }} className="px-5 px-xl-0 pb-5 pt-0 mb-5 mt-5 mb-xl-0">
                <div className='embed-video-container mt-5' dangerouslySetInnerHTML={{ __html: contents?.content?.row_embed }}></div>
              </Col>
            </Row>
          </Container>
          : ''}


      </section >

      <div className='pt-0 mt-0 pt-xl-0 mt-xl-0 px-5 px-xl-0'>
        <SectionAccomodation current={contents?.content?.row_slug} showHeader={true} headerText={'Explore More'} sizingXL={{ span: 8, offset: 2 }} />
      </div>
    </>
  )
}

Accommodation.defaultProps = {
  contents: {
    lang: 'th',
    content: {
      contents: []
    }
  },
  path: '',
  lang: 'th',
  slides: [],
  slide_events: []
}


export default Accommodation