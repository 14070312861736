import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import Pic1 from '../Images/mockup/new-icon/logo-eden+chiangmai.png';
// import Pic2 from '../Images/mockup/new-icon/logo-stellar+maewang.png';
// import Pic3 from '../Images/mockup/new-icon/logo-coasta+rayong.png';

import Pic1 from '../Images/final/10-booking/eden.png';
import Pic2 from '../Images/final/10-booking/stellar.png';
import Pic3 from '../Images/final/10-booking/rayong.png';
import { urlApi } from '../Constants/Global';

const SectionLocationExplore = ({ contents, current }) => {
  console.log("SectionLocationExplore :", contents);
  const mockup = {}

  return (
    <section className='mt-0 pt-0 mt-xl-5 pt-xl-0 location-explore-more'>
      <Container className='text-center mt-5 mt-xl-0'>
        <Row className='pt-0 pt-md-0 position-relative'>
          <Col className='pt-0 pt-md-5'>
            <div className="fs-custom-3 fw-bold pt-4 pt-md-0 text-uppercase tx-color-dark tx-spacing-10 mb-2 mb-lg-5">
              Explore More
            </div>
          </Col>
        </Row>
        <Row className='accom-list'>
          <Col>
            {contents.map((item, index) => (
              current === '/' + item.row_slug ? (
                <img src={`../${urlApi}/storage/media/${item.header_icon2}`} className='location-sign mx-3 mx-xl-4 img-grayscale' key={index} />
              ) : (
                <Link to={'/Location/' + item.row_slug} key={index}>
                  <img src={`../${urlApi}/storage/media/${item.header_icon2}`} className='location-sign mx-3 mx-xl-4' />
                </Link>
              )
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

SectionLocationExplore.defaultProps = {
  contents: [
    { img: Pic1, target: '/Eden' },
    { img: Pic2, target: '/Stellar' },
    { img: Pic3, target: '/Coasta' },
  ]
}

export default SectionLocationExplore