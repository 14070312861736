import React from 'react';
import { Row } from 'react-bootstrap';
import ContentText from './ContentText';
import ContentImage from './ContentImage';

const ContentLayout = ({ content, storage }) => {
  const cols = content.row_class.split('-');

  return (
    <>
      {cols.map((col, index) => {
        console.log(index, col);
        let result;

        switch (col) {
          case 'text':
            result = <ContentText content={content["detail" + (index + 1)]} size={12 / cols.length} />;
            break;
          case 'image':
            result = <ContentImage src={storage + content["detail" + (index + 1)]} size={12 / cols.length} />;
            break;
          default:
            result = '';
        }

        return (
          <>
            {result}
          </>
        );
      })}
    </>
  )
}

ContentLayout.defaultProps = {
  content: {},
  storage: ''
}

export default ContentLayout